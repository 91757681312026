import { useRef, useEffect } from "react";
import { useThree } from "@react-three/fiber";
import * as THREE from "three";

function Lights() {
  const { scene } = useThree();
  const ambientRef = useRef();
  const spotRef = useRef();
  const pointRef = useRef();

  useEffect(() => {
    ambientRef.current = new THREE.AmbientLight(0xffffff, 4);
    scene.add(ambientRef.current);

    spotRef.current = new THREE.SpotLight(0xffffff, 20, 10);
    spotRef.current.position.set(5, 5, 5);
    spotRef.current.castShadow = true;
    spotRef.current.shadow.mapSize.width = 6000;
    spotRef.current.shadow.mapSize.height = 6000;
    scene.add(spotRef.current);

    pointRef.current = new THREE.PointLight(0xffffff, 0.5);
    pointRef.current.position.set(0, 6, 0);
    scene.add(pointRef.current);

    return () => {
      scene.remove(ambientRef.current);
      scene.remove(spotRef.current);
      scene.remove(pointRef.current);
    };
  }, [scene]);

  return null;
}

export default Lights;
