import ReadingNookChair from './ReadingNookChair';

const ReadingNookFloor = ({ position, rotation, scale, color }) => {
  return (
    <group position={position} rotation={rotation} scale={scale}>
      <mesh position={[-0.35, 0, 0]} receiveShadow>
        <boxGeometry args={[2.2, 0.3, 2.7]} />
        <meshStandardMaterial color={color} />
      </mesh>
      <mesh position={[0.5, 0, 0]} castShadow receiveShadow>
        <boxGeometry args={[0.5, 0.3, 3.75]} />
        <meshStandardMaterial color={color} />
      </mesh>
      <mesh position={[-0.5, 0, -1.4]} rotation={[0, 0.3, 0]} receiveShadow>
        <boxGeometry args={[1.75, 0.3, 0.5]} />
        <meshStandardMaterial color={color} />
      </mesh>
      <mesh position={[-0.5, 0, 1.4]} rotation={[0, -0.3, 0]} receiveShadow>
        <boxGeometry args={[1.75, 0.3, 0.5]} />
        <meshStandardMaterial color={color} />
      </mesh>
      <ReadingNookChair position={[-0.4, 0.3, -0.3]} scale={[1.5, 1.5, 1.5]} rotation={[0, -4, 0]} />
      <mesh position={[-0.3, 0.65, 1]} castShadow>
        <cylinderGeometry args={[0.3, 0.3, 0.7, 32]} />
        <meshStandardMaterial color="#F0EAD6" /> 
      </mesh>
    </group>
  );
};

export default ReadingNookFloor;
