import { RoundedBox } from '@react-three/drei';

const Desk = ({ position }) => (
  <group position={position}>
    {/* Desk top */}
    <RoundedBox args={[5, 0.2, 2]} radius={0.1} smoothness={4} position={[0, 1.5, 0]}>
      <meshStandardMaterial color="#c1c1c1" />
    </RoundedBox>
    {/* Left leg */}
    <mesh position={[-1.5, 0.6, -0.5]}>
      <boxGeometry args={[0.2, 1.6, 0.2]} />
      <meshStandardMaterial color="#8B4513" />
    </mesh>
    {/* Right leg */}
    <mesh position={[1.5, 0.6, -0.5]}>
      <boxGeometry args={[0.2, 1.6, 0.2]} />
      <meshStandardMaterial color="#8B4513" />
    </mesh>
    {/* Left leg back */}
    <mesh position={[-1.5, 0.6, 0.5]}>
      <boxGeometry args={[0.2, 1.6, 0.2]} />
      <meshStandardMaterial color="#8B4513" />
    </mesh>
    {/* Right leg back */}
    <mesh position={[1.5, 0.6, 0.5]}>
      <boxGeometry args={[0.2, 1.6, 0.2]} />
      <meshStandardMaterial color="#8B4513" />
    </mesh>
  </group>
);

export default Desk;
