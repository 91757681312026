const Lamp = ({ position }) => {
  return (
    <group position={position}>
      {/* Base of the lamp */}
      <mesh position={[0, -0.5, -1]}>
        <cylinderGeometry args={[0.4, 0.4, 0.1, 32]} />
        <meshStandardMaterial color="#888888" />
      </mesh>

      {/* Vertical stand */}
      <mesh position={[0, 1.02, -1]}>
        <cylinderGeometry args={[0.05, 0.05, 3, 32]} />
        <meshStandardMaterial color="#888888" />
      </mesh>

      {/* Outer Lampshade */}
      <mesh position={[0, 2.7, -1]}>
        <cylinderGeometry args={[0.65, 0.65, 0.5, 32, 1, true]} />
        <meshStandardMaterial
          color="#ffffff"
          emissive="#ffffff"
          emissiveIntensity={0.2}
          transparent
          opacity={0.8}
          roughness={0.7}
          metalness={0.1}
        />
      </mesh>

      {/* Inner lampshade to add thickness */}
      <mesh position={[0, 2.7, -1]}>
        <cylinderGeometry args={[0.6, 0.6, 0.5, 32]} />
        <meshStandardMaterial
          color="#eeeeee"
          emissive="#ffffff"
          emissiveIntensity={0.1}
          transparent
          opacity={0.5}
          roughness={0.5}
        />
      </mesh>

      {/* Lightbulb */}
      <mesh position={[0, 2.55, -1]}>
        <sphereGeometry args={[0.15, 32, 32]} />
        <meshStandardMaterial color="#ffffff" emissive="#ffffe0" emissiveIntensity={1} />
      </mesh>

      {/* Light source */}
      <pointLight
        position={[0, 2.5, -1]}
        intensity={1}
        distance={5}
        decay={2}
        castShadow
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
      />
    </group>
  );
};

export default Lamp;
