import { useState } from 'react';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import styles from './MainMenu.module.css';

const MainMenu = () => {
  const [hoveredLinkIndex, setHoveredLinkIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navLinks = [
    { path: '/', label: 'HOME' },
    { path: '/about', label: 'ABOUT' },
    { path: '/music', label: 'MUSIC' },
    { path: '/yoga', label: 'YOGA' },
    { path: '/acting', label: 'ACTING' },
    { path: '/development', label: 'DEVELOPMENT' },
    { path: '/relax', label: 'RELAX' },
  ];

  const handleMouseEnter = (index) => {
    setHoveredLinkIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredLinkIndex(null);
  };

  return (
    <div className={styles.menuContainer}>
      <motion.div
        className={styles.menuIcon}
        onClick={toggleMenu}
      >
        <motion.div
          className={styles.line}
          animate={{ rotate: isOpen ? 45 : 0, y: isOpen ? 4 : 0 }}
          transition={{ duration: 0.2 }}
        ></motion.div>
        <motion.div
          className={styles.line}
          animate={{ rotate: isOpen ? -45 : 0, y: isOpen ? -4 : 0 }}
          transition={{ duration: 0.2 }}
        ></motion.div>
      </motion.div>

      <motion.div
        className={styles.navMenu}
        initial={{ opacity: 0, clipPath: 'circle(0% at 50% 50%)' }}
        animate={{
          opacity: isOpen ? 1 : 0,
          clipPath: isOpen ? 'circle(150% at 50% 50%)' : 'circle(0% at 50% 50%)',
        }}
        transition={{ duration: 0.5 }}
      >
        <nav>
          <ul className={styles.navLinks}>
            {navLinks.map((link, index) => (
              <li key={index} className={styles.navItem}>
                <NavLink
                  to={link.path}
                  exact
                  activeClassName={styles.active}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  className={`${styles.navLink} ${hoveredLinkIndex !== null && hoveredLinkIndex !== index ? styles.otherLinks : ''}`}
                  onClick={toggleMenu} 
                >
                  {link.label}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </motion.div>
    </div>
  );
};

export default MainMenu;
