const ReadingNookChair = ({ position, scale, rotation }) => {
  return (
    <group position={position} scale={scale} rotation={rotation}>
      {/* Chair seat */}
      <mesh position={[0, 0.4, 0]} castShadow receiveShadow>
        <boxGeometry args={[0.5, 0.1, 0.5]} />
        <meshStandardMaterial color="#d3d3d3" />
      </mesh>
      {/* Chair back */}
      <mesh position={[0, 0.7, 0.2]} rotation={[Math.PI / 8, 0, 0]} castShadow receiveShadow>
        <boxGeometry args={[0.5, 0.7, 0.1]} />
        <meshStandardMaterial color="#d3d3d3" />
      </mesh>
      {/* Chair legs */}
      <mesh position={[-0.2, 0.2, -0.2]} castShadow receiveShadow>
        <boxGeometry args={[0.05, 0.4, 0.05]} />
        <meshStandardMaterial color="#8B4513" />
      </mesh>
      <mesh position={[0.2, 0.2, -0.2]} castShadow receiveShadow>
        <boxGeometry args={[0.05, 0.4, 0.05]} />
        <meshStandardMaterial color="#8B4513" />
      </mesh>
      <mesh position={[-0.2, 0.2, 0.2]} castShadow receiveShadow>
        <boxGeometry args={[0.05, 0.4, 0.05]} />
        <meshStandardMaterial color="#8B4513" />
      </mesh>
      <mesh position={[0.2, 0.2, 0.2]} castShadow receiveShadow>
        <boxGeometry args={[0.05, 0.4, 0.05]} />
        <meshStandardMaterial color="#8B4513" />
      </mesh>
      {/* Armrests */}
      <mesh position={[-0.3, 0.5, 0]} castShadow receiveShadow>
        <boxGeometry args={[0.05, 0.05, 0.5]} />
        <meshStandardMaterial color="#8B4513" />
      </mesh>
      <mesh position={[0.3, 0.5, 0]} castShadow receiveShadow>
        <boxGeometry args={[0.05, 0.05, 0.5]} />
        <meshStandardMaterial color="#8B4513" />
      </mesh>
    </group>
  );
};

export default ReadingNookChair;
