import { useMemo, useRef } from 'react';
import { LatheGeometry, Vector2 } from 'three';
import { useFrame } from '@react-three/fiber';

const WineGlass = ({ position, rotation, scale }) => {
  const outerGlassShape = useMemo(() => {
    const points = [];
    points.push(new Vector2(0.25, 0));
    points.push(new Vector2(0.3, 0.1));
    points.push(new Vector2(0.35, 0.2));
    points.push(new Vector2(0.4, 0.35));
    points.push(new Vector2(0.35, 0.5));
    points.push(new Vector2(0.25, 0.6));
    points.push(new Vector2(0.2, 0.7));
    return new LatheGeometry(points, 32);
  }, []);

  const wineShape = useMemo(() => {
    const points = [];
    points.push(new Vector2(0.2, 0));
    points.push(new Vector2(0.25, 0.1));
    points.push(new Vector2(0.3, 0.2));
    points.push(new Vector2(0.35, 0.35));
    points.push(new Vector2(0.3, 0.45));
    points.push(new Vector2(0.2, 0.5));
    return new LatheGeometry(points, 32);
  }, []);

  return (
    <group position={position} rotation={rotation} scale={scale}>
      {/* Outer Glass */}
      <mesh geometry={outerGlassShape} position={[0, 0.75, 0]} receiveShadow castShadow>
        <meshStandardMaterial color="#ffffff" opacity={0.2} transparent />
      </mesh>
      {/* Wine */}
      <mesh geometry={wineShape} position={[0, 0.75, 0]} receiveShadow castShadow>
        <meshStandardMaterial color="#8B0000" opacity={0.8} transparent />
      </mesh>
      {/* Stem */}
      <mesh position={[0, 0.4, 0]} receiveShadow castShadow>
        <cylinderGeometry args={[0.05, 0.05, 0.8, 32]} />
        <meshStandardMaterial color="#ffffff" opacity={0.5} transparent />
      </mesh>
      {/* Base */}
      <mesh position={[0, 0, 0]} receiveShadow castShadow>
        <cylinderGeometry args={[0.2, 0.2, 0.05, 32]} />
        <meshStandardMaterial color="#ffffff" opacity={0.5} transparent />
      </mesh>
      {/* Coaster */}
      <mesh position={[0, -0.05, 0]} receiveShadow castShadow>
        <cylinderGeometry args={[0.3, 0.3, 0.02, 32]} />
        <meshStandardMaterial color="#000000" />
      </mesh>
    </group>
  );
};

const Candle = ({ position }) => {
  const candleRef = useRef();
  const flameLightRef = useRef();

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    const intensity = 0.3 + Math.sin(time * 10) * 0.09; 
    flameLightRef.current.intensity = intensity;
  });

  return (
    <group position={position} ref={candleRef}>
      {/* Candle Holder */}
      <mesh position={[0, 0.02, 0]} receiveShadow castShadow>
        <cylinderGeometry args={[0.22, 0.22, 0.05, 32]} />
        <meshStandardMaterial color="#000000" />
      </mesh>
      {/* Candle Body */}
      <mesh position={[0, 0.3, 0]} receiveShadow castShadow>
        <cylinderGeometry args={[0.16, 0.16, 0.4, 32]} />
        <meshStandardMaterial color="#f5f5f5" opacity={0.9} transparent />
      </mesh>
      {/* Glass Cylinder */}
      <mesh position={[0, 0.3, 0]} receiveShadow castShadow>
        <cylinderGeometry args={[0.2, 0.2, 0.5, 32]} />
        <meshStandardMaterial color="#ffffff" opacity={0.3} transparent />
      </mesh>
      {/* Flame */}
      <mesh position={[0, 0.5, 0]} receiveShadow castShadow>
        <sphereGeometry args={[0.05, 16, 16]} />
        <meshStandardMaterial color="#ff4500" emissive="#ff8c00" emissiveIntensity={0.5} />
      </mesh>
      {/* Light Emission */}
      <pointLight ref={flameLightRef} position={[0, 1, 0]} color="#ff8c00" intensity={1} distance={3} />
    </group>
  );
};

const GlassTable = ({ position, rotation, scale }) => {
  return (
    <group position={position} rotation={rotation} scale={scale}>
      {/* Tabletop */}
      <mesh position={[0, 1, 0]} receiveShadow castShadow>
        <boxGeometry args={[4, 0.1, 2]} />
        <meshStandardMaterial color="#d9d9d9" opacity={0.5} transparent />
      </mesh>
      {/* Left Leg */}
      <mesh position={[-1.5, 0.5, 0]} receiveShadow castShadow>
        <boxGeometry args={[0.3, 1, 1]} />
        <meshStandardMaterial color="#4d4d4d" />
      </mesh>
      {/* Right Leg */}
      <mesh position={[1.5, 0.5, 0]} receiveShadow castShadow>
        <boxGeometry args={[0.3, 1, 1]} />
        <meshStandardMaterial color="#4d4d4d" />
      </mesh>
      {/* Wineglasses */}
      <WineGlass position={[-1.5, 1.1, 0.5]} scale={[0.75, 0.7, 0.75]} />
      <WineGlass position={[-0.5, 1.1, 0.5]} scale={[0.75, 0.7, 0.75]} />
      {/* Candle */}
      <Candle position={[1.2, 1.1, -0.2]} />
    </group>
  );
};

export default GlassTable;
