import { motion, useAnimation } from 'framer-motion';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useRef } from 'react';
import styles from './WorkScroller.module.css';

import ispSolutions from '../../assets/apps/ispSolutions.webp';
import kyle from '../../assets/apps/kyle.webp';
import juliet from '../../assets/apps/juliet.webp';
import fishback from '../../assets/apps/fishback.webp';
import ca from '../../assets/apps/ca.webp';
import shelteringScholars from '../../assets/apps/shelteringScholars.webp';

const apps = [
    { id: 1, name: 'Sheltering Scholars', image: shelteringScholars, link: 'https://www.shelteringscholars.org/' },
    { id: 2, name: 'Cultural Architecture', image: ca, link: 'https://www.culturalarchitecture.com/' },
    { id: 3, name: 'Fishback Studio', image: fishback, link: 'https://www.fishbackstudio.com/' },
    { id: 4, name: 'Juliet Doherty Official', image: juliet, link: 'https://www.julietdoherty.com/' },
    { id: 6, name: 'Kyle Jennings Music Official', image: kyle, link: 'https://www.kylejenningsmusic.com/' },
    { id: 6, name: 'ISP Solutions', image: ispSolutions, link: 'https://www.ispsolutions.space' },
];

const repeatedApps = [...apps, ...apps, ...apps];

const WorkScroller = () => {
    const [hoveredLinkIndex, setHoveredLinkIndex] = useState(null);
    const [isCardHovered, setIsCardHovered] = useState(false);

    const isExtraSmallScreen = useMediaQuery({ maxWidth: 400 });
    const isSmallScreen = useMediaQuery({ maxWidth: 600 });
    const isMediumScreen = useMediaQuery({ minWidth: 601, maxWidth: 1200 });
    const isLargeScreen = useMediaQuery({ minWidth: 1201 });

    const duration = isExtraSmallScreen ? 10 : isSmallScreen ? 20 : isMediumScreen ? 30 : isLargeScreen ? 40 : 30;

    const controls = useAnimation();
    const animationProgress = useRef(0);

    useEffect(() => {
        if (!isCardHovered) {
            controls.start({
                x: ['-100%', '0%'],
                transition: {
                    repeat: Infinity,
                    duration: duration,
                    ease: 'linear',
                    onUpdate: (latest) => {
                        animationProgress.current = latest;
                    }
                }
            });
        }
    }, [controls, duration, isCardHovered]);

    const handleLinkMouseEnter = (index) => {
        setHoveredLinkIndex(index);
    };

    const handleLinkMouseLeave = () => {
        setHoveredLinkIndex(null);
    };

    const handleCardMouseEnter = () => {
        setIsCardHovered(true);
        controls.stop();
    };

    const handleCardMouseLeave = () => {
        setIsCardHovered(false);
        controls.start({
            x: [animationProgress.current, '-100%'],
            transition: { repeat: Infinity, duration: duration, ease: 'linear' }
        });
    };

    return (
        <div className={styles.workScrollerContainer}>
            <div className={styles.projectLinksContainer}>
                <ul className={styles.projectLinks}>
                    {apps.map((app, index) => (
                        <li key={app.id} className={styles.projectLinkItem}>
                            <motion.a
                                href={app.link}
                                target='_blank'
                                rel='noreferrer'
                                className={`${styles.projectLink} ${hoveredLinkIndex !== null && hoveredLinkIndex !== index ? styles.otherLinks : ''}`}
                                onMouseEnter={() => handleLinkMouseEnter(index)}
                                onMouseLeave={handleLinkMouseLeave}
                            >
                                {app.name}
                            </motion.a>
                        </li>
                    ))}
                </ul>
            </div>

            <motion.ul
                className={styles.appRow}
                animate={controls}
                initial={{ x: ['-100%', '0%'] }}
            >
                {repeatedApps.map(app => (
                    <motion.li
                        key={app.id}
                        className={styles.appCard}
                        onMouseEnter={handleCardMouseEnter}
                        onMouseLeave={handleCardMouseLeave}
                        whileHover={{ y: -10 }} 
                    >
                        <a href={app.link} target='_blank' rel='noreferrer'>
                            <img src={app.image} alt={app.name} className={styles.appImage} />
                        </a>
                    </motion.li>
                ))}
            </motion.ul>
        </div>
    );
};

export default WorkScroller;
