import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function useTitle() {
  const location = useLocation();

  useEffect(() => {
    const baseTitle = "Jordan Olguin";
    let title = baseTitle;

    switch (location.pathname) {
      case "/":
        title = "jordanolguin.com";
        break;
      case "/about":
        title = "A B O U T | " + baseTitle;
        break;
      case "/music":
        title = "M U S I C | " + baseTitle;
        break;
        case "/relax":
        title = "R E L A X | " + baseTitle;
        break;
        case "/development":
        title = "D E V E L O P M E N T | " + baseTitle;
        break;
        case "/yoga":
        title = "Y O G A | " + baseTitle;
        break;
        case "/acting":
        title = "A C T I N G | " + baseTitle;
        break;
      default:
        title = baseTitle;
        break;
    }

    document.title = title;
  }, [location]);
}

export default useTitle;
