import { RoundedBox } from '@react-three/drei';

const Armchair = ({ position, rotation, scale }) => {
  return (
    <mesh position={position} rotation={rotation} scale={scale} receiveShadow castShadow>
      <group>
        {/* Main armchair base */}
        <RoundedBox position={[0, 0.5, 0]} args={[2.5, 1, 2]} radius={0.2} smoothness={4}>
          <meshStandardMaterial color="#6B8E8D" />
        </RoundedBox>
        {/* Left armrest */}
        <RoundedBox position={[-1.35, 1, 0]} args={[0.5, 1, 1.8]} radius={0.2} smoothness={4}>
          <meshStandardMaterial color="#6B8E8D" />
        </RoundedBox>
        {/* Right armrest */}
        <RoundedBox position={[1.35, 1, 0]} args={[0.5, 1, 1.8]} radius={0.2} smoothness={4}>
          <meshStandardMaterial color="#6B8E8D" />
        </RoundedBox>
        {/* Backrest */}
        <RoundedBox position={[0, 1.75, -0.75]} args={[3, 1.5, 0.5]} radius={0.2} smoothness={4}>
          <meshStandardMaterial color="#6B8E8D" />
        </RoundedBox>
        {/* Cushion */}
        <RoundedBox position={[0, 0.75, 0.5]} args={[2.3, 0.6, 1.7]} radius={0.2} smoothness={4}>
          <meshStandardMaterial color="#5A7A7A" />
        </RoundedBox>
        {/* Seat cushion */}
        <RoundedBox position={[0, 0.4, 0.5]} args={[2.3, 0.6, 1.7]} radius={0.2} smoothness={4}>
          <meshStandardMaterial color="#6B8E8D" />
        </RoundedBox>
        {/* Seat legs */}
        <mesh position={[-1, 0.1, 1]}>
          <cylinderGeometry args={[0.2, 0.2, 0.4, 32]} />
          <meshStandardMaterial color="#4A5D5D" />
        </mesh>
        <mesh position={[1, 0.1, 1]}>
          <cylinderGeometry args={[0.2, 0.2, 0.4, 32]} />
          <meshStandardMaterial color="#4A5D5D" />
        </mesh>
        <mesh position={[-1, 0.1, -0.8]}>
          <cylinderGeometry args={[0.2, 0.2, 0.4, 32]} />
          <meshStandardMaterial color="#4A5D5D" />
        </mesh>
        <mesh position={[1, 0.1, -0.8]}>
          <cylinderGeometry args={[0.2, 0.2, 0.4, 32]} />
          <meshStandardMaterial color="#4A5D5D" />
        </mesh>
      </group>
    </mesh>
  );
};

export default Armchair;
