import { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

const Clock = ({ position = [0, 0, 0], rotation = [0, 0, 0], scale = [1, 1, 1] }) => {
  const hourHandRef = useRef();
  const minuteHandRef = useRef();

  useFrame(() => {
    const date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const hourRotation = (hours % 12) * (Math.PI / 6) + (Math.PI / 360) * minutes + (Math.PI / 21600) * seconds;
    const minuteRotation = minutes * (Math.PI / 30) + (Math.PI / 1800) * seconds;

    if (hourHandRef.current) {
      hourHandRef.current.rotation.z = -hourRotation;
    }

    if (minuteHandRef.current) {
      minuteHandRef.current.rotation.z = -minuteRotation;
    }
  });

  return (
    <mesh position={position} rotation={rotation} scale={scale}>
      {/* Clock Circle */}
      <mesh>
        <torusGeometry args={[1.4, 0.1, 16, 100]} />
        <meshStandardMaterial color="#e0e0e0" />
      </mesh>

      {/* Inner Light */}
      <pointLight 
        position={[0, 0, 0.1]} 
        intensity={1.5} 
        distance={4} 
        decay={0.1} 
        color="#ffffff" 
      />

      {/* Hour Hand */}
      <mesh ref={hourHandRef} position={[0, 0, 0.1]}>
        <cylinderGeometry args={[0.03, 0.03, 0.6, 32]} />
        <meshStandardMaterial color="#000000" />
        <mesh position={[0, 0.35, 0]}>
          <cylinderGeometry args={[0.03, 0.03, 0.7, 32]} />
          <meshStandardMaterial color="#000000" />
        </mesh>
      </mesh>

      {/* Minute Hand */}
      <mesh ref={minuteHandRef} position={[0, 0, 0.1]}>
        <cylinderGeometry args={[0.03, 0.03, 0.6, 32]} />
        <meshStandardMaterial color="#000000" />
        <mesh position={[0, 0.6, 0]}>
          <cylinderGeometry args={[0.03, 0.03, 1.2, 32]} />
          <meshStandardMaterial color="#000000" />
        </mesh>
      </mesh>

      {/* Center Circle */}
      <mesh position={[0, 0, 0.11]}>
        <circleGeometry args={[0.14, 32]} />
        <meshStandardMaterial color="#000000" />
      </mesh>

      {/* 12, 3, 6, 9 Markers */}
      {[0, Math.PI / 2, Math.PI, (3 * Math.PI) / 2].map((angle, index) => (
        <mesh key={index} position={[Math.cos(angle) * 1.4, Math.sin(angle) * 1.4, 0.1]} rotation={[0, 0, angle]}>
          <boxGeometry args={[0.05, 0.3, 0.03]} />
          <meshStandardMaterial color="#000000" />
        </mesh>
      ))}
    </mesh>
  );
};

export default Clock;
