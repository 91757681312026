import ContactForm from '../../components/ContactForm/ContactForm';
import Footer from '../../components/Footer/Footer';
import wedding from '../../assets/wedding.webp';
import styles from './Acting.module.css';

const Acting = () => {
  const bio = `Jordan Olguin is a versatile actor trained in Television and Film acting, with additional training in comedy. He has starred in various acclaimed stage productions, including the role of George Gibbs in "Our Town" with Mother Road Theatre Co., Jay Gatsby in "The Great Gatsby, The Musical" with Emmerson Collaborative, and Jaime in "Lava" at The Vortex Theatre. Jordan has also showcased his talent in award-winning short and feature-length films, establishing himself as a dynamic presence in the acting world.`;

  const theaterCredits = [
    { role: 'George Gibbs', production: 'Our Town', company: 'Mother Road Theatre Co.' },
    { role: 'Jay Gatsby', production: 'The Great Gatsby, The Musical', company: 'Emmerson Collaborative' },
    { role: 'Jaime', production: 'Lava', company: 'The Vortex Theatre' },
  ];

  const filmCredits = [
    { title: 'ABQ Brew', role: 'Scott', director: 'Lindsy Campbell' },
    { title: 'Condom Negotiation', role: 'Ryan', director: 'Gregg Conde' },
    { title: 'Coyote Cage', role: 'Marco', director: 'Michael Perez' },
    { title: 'The Divorce', role: 'Danny', director: 'Lindsy Campbell' },
    { title: 'Didn’t Say It', role: 'Tom', director: 'Tim Stafford' },
  ];

  return (
    <>
    <div className={styles.actingContainer}>
        <img src={wedding} alt="Jordan Olguin in a suit" className={styles.bannerImage} />
      <div className={styles.bioContainer}>
        <p className={styles.bio}>
          {bio}
        </p>
      </div>
      <h2 className={styles.title}>THEATRE CREDITS</h2>
      <ul className={styles.creditsList}>
        {theaterCredits.map((credit, index) => (
          <li key={index} className={styles.creditItem}>
            <span className={styles.role}>{credit.role}</span> in <span className={styles.production}>{credit.production}</span>, {credit.company}
          </li>
        ))}
      </ul>

      <h2 className={styles.title}>FILM CREDITS</h2>
      <ul className={styles.creditsList}>
        {filmCredits.map((credit, index) => (
          <li key={index} className={styles.creditItem}>
            <span className={styles.title}>{credit.title}</span> as <span className={styles.role}>{credit.role}</span>, directed by <span className={styles.director}>{credit.director}</span>
          </li>
        ))}
      </ul>

      <a
        href="https://www.imdb.com/name/nm9819820/?ref_=nv_sr_srsg_0_tt_0_nm_8_in_0_q_jordan%2520olguin"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.imdbLink}
      >
        IMDb
      </a>
      <ContactForm page="acting" />
    </div>
    <Footer />
    </>
  );
};

export default Acting;
