const Piano = ({ position, rotation, scale }) => {
  return (
    <group position={position} rotation={rotation} scale={scale}>
      {/* Keyboard Outer Box */}
      <mesh position={[0, 1, 0]}>
        <boxGeometry args={[4.2, 0.25, 1.2]} />
        <meshStandardMaterial color="#000000" />
      </mesh>
      {/* White Keys */}
      {Array.from({ length: 14 }, (_, i) => (
        <mesh key={`white-key-${i}`} position={[i * 0.28 - 1.82, 1.15, 0]}>
          <boxGeometry args={[0.26, 0.1, 1]} />
          <meshStandardMaterial color="#FFFFFF" />
        </mesh>
      ))}
      {/* Black Keys */}
      {Array.from({ length: 13 }, (_, i) => {
        const blackKeyPositions = [0, 1, 3, 4, 5, 7, 8, 10, 11, 12, 13, 14];
        if (!blackKeyPositions.includes(i)) return null;
        return (
          <mesh key={`black-key-${i}`} position={[i * 0.28 - 1.67, 1.2, -0.25]}>
            <boxGeometry args={[0.15, 0.15, 0.5]} />
            <meshStandardMaterial color="#000000" />
          </mesh>
        );
      })}
      {/* Stand Legs */}
      <mesh position={[-1.2, 0.3, -0.2]} rotation={[0, 0, Math.PI / 4]}>
        <cylinderGeometry args={[0.05, 0.05, 2]} />
        <meshStandardMaterial color="#000000" />
      </mesh>
      <mesh position={[1.2, 0.3, 0.2]} rotation={[0, 0, Math.PI / 4]}>
        <cylinderGeometry args={[0.05, 0.05, 2]} />
        <meshStandardMaterial color="#000000" />
      </mesh>
      <mesh position={[1.2, 0.3, -0.2]} rotation={[0, 0, -Math.PI / 4]}>
        <cylinderGeometry args={[0.05, 0.05, 2]} />
        <meshStandardMaterial color="#000000" />
      </mesh>
      <mesh position={[-1.2, 0.3, 0.2]} rotation={[0, 0, -Math.PI / 4]}>
        <cylinderGeometry args={[0.05, 0.05, 2]} />
        <meshStandardMaterial color="#000000" />
      </mesh>
    </group>
  );
};

export default Piano;
