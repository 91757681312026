import { RoundedBox } from '@react-three/drei';

const YogaMat = ({ position, rotation, scale, color, block1Rotation = [0, 0.5, 0], block2Rotation = [0, -0.3, 0] }) => {
  const blockPosition1 = [position[0] - 1.7, position[1] + 0.15, position[2] + 2.2];
  const blockPosition2 = [position[0] - 1.5, position[1] + 0.5, position[2] + 1.4]; 

  return (
    <group>
      <mesh
        position={position}
        rotation={rotation}
        scale={scale}
        castShadow
        receiveShadow
      >
        <boxGeometry args={[2, 0.1, 6]} />
        <meshStandardMaterial color={color} />
      </mesh>
      <RoundedBox
        position={blockPosition1}
        args={[1, 0.4, 0.5]} 
        radius={0.1}
        smoothness={4} 
        rotation={block1Rotation}
        castShadow
        receiveShadow
      >
        <meshStandardMaterial color="#87CEEB" />
      </RoundedBox>
      <RoundedBox
        position={blockPosition2}
        args={[0.5, 1, 0.5]} 
        radius={0.1} 
        smoothness={4} 
        rotation={block2Rotation}
        castShadow
        receiveShadow
      >
        <meshStandardMaterial color="#87CEEB" />
      </RoundedBox>
    </group>
  );
};

export default YogaMat;
