import { useState } from 'react';
import bass from '../../assets/bass.webp';
import Discography from '../../components/Discography/Discography';
import ContactForm from '../../components/ContactForm/ContactForm';
import Footer from '../../components/Footer/Footer';
import styles from './Music.module.css';

const Music = () => {
  const [hoveredLinkIndex, setHoveredLinkIndex] = useState(null);

  const bio = `Jordan Olguin's music journey began on the stage at the tender age of 3, where he captivated audiences with his singing and dancing. By 11, the guitar had become his instrument of choice, setting the foundation for a lifelong passion for music. As a multi-instrumentalist, Jordan plays guitar, drums, bass, piano, harmonica, handpan, and more, bringing a rich tapestry of sounds to his recordings. His production skills complement his instrumental prowess, making him a versatile recording artist. Jordan has also scored films and produces electronic dance music, showcasing his versatility across different musical genres. Influenced by legends like Lenny Kravitz, Aerosmith, The Beatles, and Duran Duran, Jordan's music is a tribute to the rock and roll greats, with a style uniquely his own.`;

  const streamingLinks = [
    { name: 'SPOTIFY', url: 'https://open.spotify.com/artist/6ww1npEkRMmdjUGua5HWsX?si=hp_LRID5TxexFBcggj5dyw&dl_branch=1' },
    { name: 'APPLE MUSIC', url: 'https://music.apple.com/us/artist/jordan-olguin/1575006049' },
  ];

  const handleMouseEnter = (index) => {
    setHoveredLinkIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredLinkIndex(null);
  };

  return (
    <>
    <div className={styles.musicContainer}>
      <img src={bass} alt="Jordan Olguin playing bass" className={styles.bannerImage} />
      <div className={styles.bioContainer}>
        <p className={styles.bio}>
          {bio}
        </p>
      </div>
      <h2 className={styles.title}>LISTEN NOW</h2>
      <div className={styles.linksContainer}>
        {streamingLinks.map((link, index) => (
          <a
            key={link.name}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`${styles.link} ${hoveredLinkIndex !== null && hoveredLinkIndex !== index ? styles.otherLinks : ''}`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            {link.name}
          </a>
        ))}
      </div>
      <Discography />
      <ContactForm page="music" />
    </div>
    <Footer />
    </>
  );
};

export default Music;
