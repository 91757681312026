import { useRef, useEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";

function Particles() {
  const particlesRef = useRef();
  const { scene } = useThree();

  useEffect(() => {
    const particles = new THREE.Group();
    const particleMaterial = new THREE.MeshToonMaterial({
      color: 0xffff00,
      side: THREE.DoubleSide,
    });
    const particleGeometry = new THREE.SphereGeometry(0.01, 32, 32);

    for (let i = 0; i < 400; i++) {
      const particle = new THREE.Mesh(particleGeometry, particleMaterial);
      particle.position.set(
        (Math.random() - 0.5) * 10,
        (Math.random() - 0.5) * 10,
        (Math.random() - 0.5) * 10
      );
      particle.rotation.set(
        Math.random() * Math.PI,
        Math.random() * Math.PI,
        Math.random() * Math.PI
      );
      particles.add(particle);
    }

    scene.add(particles);
    particlesRef.current = particles;

    return () => {
      scene.remove(particles);
    };
  }, [scene]);

  useFrame(() => {
    if (particlesRef.current) {
      particlesRef.current.rotation.y += 0.001;
      particlesRef.current.rotation.x += 0.001;
    }
  });

  return null;
}

export default Particles;
