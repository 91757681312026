import Room from "../../components/threejsComponents/Room/Room";
import styles from "./Relax.module.css";

const Relax = () => {
  const currentTime = new Date().getHours();

  // Adjust background color based on time of day
  const backgroundColors = {
    dawn: styles.dawn,
    morning: styles.morning,
    noon: styles.noon,
    afternoon: styles.afternoon,
    evening: styles.evening,
    night: styles.night,
  };

  let background = styles.night;

  if (currentTime >= 5 && currentTime < 7) {
    background = backgroundColors.dawn;
  } else if (currentTime >= 7 && currentTime < 10) {
    background = backgroundColors.morning;
  } else if (currentTime >= 10 && currentTime < 14) {
    background = backgroundColors.noon;
  } else if (currentTime >= 14 && currentTime < 17) {
    background = backgroundColors.afternoon;
  } else if (currentTime >= 17 && currentTime < 19) {
    background = backgroundColors.evening;
  }

  return (
    <div className={`${styles.relaxWrapper} ${background}`}>
      <div className={styles.relaxContainer}>
        <Room currentTime={currentTime} />
      </div>
    </div>
  );
};

export default Relax;
