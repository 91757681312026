const CoffeeTable = ({ position, rotation, scale, color }) => (
  <mesh position={position} rotation={rotation} scale={scale}>
    {/* Table top */}
    <boxGeometry args={[2, 0.1, 1]} />
    <meshStandardMaterial color={color} />
    {/* Legs */}
    <mesh position={[-0.9, -0.5, -0.45]}>
      <boxGeometry args={[0.1, 1, 0.1]} />
      <meshStandardMaterial color={color} />
    </mesh>
    <mesh position={[0.9, -0.5, -0.45]}>
      <boxGeometry args={[0.1, 1, 0.1]} />
      <meshStandardMaterial color={color} />
    </mesh>
    <mesh position={[-0.9, -0.5, 0.45]}>
      <boxGeometry args={[0.1, 1, 0.1]} />
      <meshStandardMaterial color={color} />
    </mesh>
    <mesh position={[0.9, -0.5, 0.45]}>
      <boxGeometry args={[0.1, 1, 0.1]} />
      <meshStandardMaterial color={color} />
    </mesh>
  </mesh>
);

export default CoffeeTable;
