import { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

const FanBlade = ({ position, rotation }) => (
  <mesh position={position} rotation={rotation} castShadow>
    <boxGeometry args={[0.8, 0.02, 0.15]} />
    <meshStandardMaterial color="#333" />
  </mesh>
);

const FanBody = () => (
  <mesh position={[0, 0, 0]} castShadow>
    <cylinderGeometry args={[0.15, 0.15, 0.1, 32]} />
    <meshStandardMaterial color="#333" />
  </mesh>
);

const FanLight = () => (
  <pointLight position={[0, -0.25, 0]} intensity={0.7} distance={5} decay={2} color="white" />
);

const CeilingAttachment = () => (
  <mesh position={[0, 0.1, 0]} castShadow>
    <cylinderGeometry args={[0.05, 0.05, 0.2, 32]} />
    <meshStandardMaterial color="#333" />
  </mesh>
);

const CeilingFan = ({ position, rotation, scale }) => {
  const groupRef = useRef();

  // Rotate the fan
  useFrame(() => {
    if (groupRef.current) {
      groupRef.current.rotation.y += 0.01;
    }
  });

  return (
    <group position={position} rotation={rotation} scale={scale} ref={groupRef}>
      <CeilingAttachment />
      <FanBody />
      <FanLight />
      <FanBlade position={[0.5, 0, 0]} rotation={[0.1, 0, 0]} />
      <FanBlade position={[-0.5, 0, 0]} rotation={[0.1, Math.PI, 0]} />
      <FanBlade position={[0, 0, 0.5]} rotation={[0.1, Math.PI / 2, 0]} />
      <FanBlade position={[0, 0, -0.5]} rotation={[0.1, -Math.PI / 2, 0]} />
    </group>
  );
};

export default CeilingFan;
