const NikonStyleCamera = ({ position, rotation, scale }) => (
  <group position={position} rotation={rotation} scale={scale}>
    {/* Camera body */}
    <mesh position={[0, 0.2, 0]} castShadow receiveShadow>
      <boxGeometry args={[1, 0.6, 0.4]} />
      <meshStandardMaterial color="#2f2f2f" />
    </mesh>
    {/* Lens */}
    <mesh position={[-0.005, 0.2, 0.1]} rotation={[Math.PI / 2, 0, 0]} castShadow receiveShadow>
      <cylinderGeometry args={[0.2, 0.2, 0.3, 32]} />
      <meshStandardMaterial color="#888888" />
    </mesh>
    <mesh position={[0.009, 0.2, 0.25]} rotation={[Math.PI / 2, 0, 0]} castShadow receiveShadow>
      <cylinderGeometry args={[0.17, 0.17, 0.1, 32]} />
      <meshStandardMaterial color="#ccc" />
    </mesh>
    {/* Top Details - Dials */}
    <mesh position={[-0.25, 0.5, 0.15]} castShadow receiveShadow>
      <cylinderGeometry args={[0.05, 0.05, 0.1, 32]} />
      <meshStandardMaterial color="#888888" />
    </mesh>
    <mesh position={[-0.25, 0.5, -0.15]} castShadow receiveShadow>
      <cylinderGeometry args={[0.05, 0.05, 0.1, 32]} />
      <meshStandardMaterial color="#888888" />
    </mesh>
  </group>
);

export default NikonStyleCamera;
