const LightSettings = ({ windowLightColor, windowLightIntensity }) => (
  <>
    <ambientLight intensity={0.5} color="#ffffff" />
    <pointLight position={[10, 10, 10]} intensity={1} color="#ffd7a1" />
    <spotLight position={[0, 10, 0]} angle={0.3} penumbra={1} intensity={1.5} color="#ffe4b5" />
    <pointLight position={[-5, 5, 5]} intensity={0.7} color="#fff0e5" />
    <pointLight position={[5, 5, -5]} intensity={0.7} color="#fff0e5" />
    <directionalLight
      position={[-10, 10, 0]}
      intensity={windowLightIntensity}
      color={windowLightColor}
      castShadow
    />
  </>
);

export default LightSettings;
