import CityScene from "../../components/City/CityScene";
import styles from "./Home.module.css";

export default function Home() {
  return (
    <div className={styles.homeContainer}>
      <h1 className={styles.homeTitle}>JORDAN OLGUIN</h1>
      <CityScene />
    </div>
  );
}
