const Leaf = ({ position, rotation, scale }) => (
  <mesh position={position} rotation={rotation} scale={scale}>
    <coneGeometry args={[0.1, 1.4, 8]} />
    <meshStandardMaterial color="#8A9A5B" />
  </mesh>
);

const OuterPot = ({ position, scale }) => (
  <group position={position} scale={scale}>
    <mesh position={[0, 0.45, 0]}>
      <cylinderGeometry args={[0.5, 0.5, 0.9, 32]} />
      <meshStandardMaterial color="#A9A9A9" />
    </mesh>
    <mesh position={[0, 0.9, 0]}>
      <cylinderGeometry args={[0.55, 0.55, 0.1, 32]} />
      <meshStandardMaterial color="#A9A9A9" />
    </mesh>
  </group>
);

const Plant = ({ position, scale }) => (
  <group position={position} scale={scale}>
    {/* Inner Pot */}
    <mesh position={[0, 0.5, 0]}>
      <cylinderGeometry args={[0.45, 0.45, 1, 32]} />
      <meshStandardMaterial color="#5C4033" />
    </mesh>
    {/* Outer Pot */}
    <OuterPot position={[0, 0, 0]} scale={[1, 1, 1]}/>
    {/* Leaves */}
    <Leaf position={[0, 0.7, 0]} rotation={[0, 0, Math.PI / 12]} scale={[1, 1, 1]} />
    <Leaf position={[0.2, 0.8, 0.2]} rotation={[0, Math.PI / 6, Math.PI / 12]} scale={[0.9, 0.9, 0.9]} />
    <Leaf position={[-0.2, 0.7, 0.2]} rotation={[0, -Math.PI / 6, Math.PI / 12]} scale={[0.9, 0.9, 0.9]} />
    <Leaf position={[0.2, 0.8, -0.2]} rotation={[0, Math.PI / 6, -Math.PI / 12]} scale={[0.9, 0.9, 0.9]} />
    <Leaf position={[-0.13, 0.7, -0.2]} rotation={[0, -Math.PI / 6, -Math.PI / 12]} scale={[0.9, 0.9, 0.9]} />
    <Leaf position={[0.2, 0.9, 0]} rotation={[0, 0, Math.PI / 12]} scale={[0.8, 0.8, 0.8]} />
    <Leaf position={[-0.2, 0.9, 0]} rotation={[0, 0, -Math.PI / 12]} scale={[0.8, 0.8, 0.8]} />
    <Leaf position={[0, 0.9, 0.3]} rotation={[0, Math.PI / 6, Math.PI / 12]} scale={[0.8, 0.8, 0.8]} />
    <Leaf position={[0, 0.9, -0.3]} rotation={[0, -Math.PI / 6, -Math.PI / 12]} scale={[0.8, 0.8, 0.8]} />
  </group>
);

export default Plant;
