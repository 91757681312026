const Book = ({ position, rotation, scale, color }) => {
  return (
    <mesh position={position} rotation={rotation} scale={scale}>
      <boxGeometry args={[0.2, 0.4, 0.035]} />
      <meshStandardMaterial color={color} />
    </mesh>
  );
};

const BookShelf = ({ position, rotation, scale }) => {
  return (
    <mesh position={position} rotation={rotation} scale={scale}>
      {/* Create the main box for the bookshelf */}
      <boxGeometry args={[1, 2, -0.3]} />
      <meshStandardMaterial color="#5D3A1A" />

      {/* Create the shelves */}
      <mesh position={[0, 1, 0.03]}>
        <boxGeometry args={[1, 0.1, 0.3]} />
        <meshStandardMaterial color="#8B4513" />
      </mesh>
      <mesh position={[0, 0.4, 0.03]}>
        <boxGeometry args={[1, 0.1, 0.3]} />
        <meshStandardMaterial color="#8B4513" />
      </mesh>
      <mesh position={[0, -1, 0.03]}>
        <boxGeometry args={[1, 0.1, 0.3]} />
        <meshStandardMaterial color="#8B4513" />
      </mesh>
      <mesh position={[0, -0.3, 0.03]}>
        <boxGeometry args={[1, 0.1, 0.3]} />
        <meshStandardMaterial color="#8B4513" />
      </mesh>

      <mesh position={[-0.5, -0.05, 0.03]}>
        <boxGeometry args={[0.1, 2.2, 0.3]} />
        <meshStandardMaterial color="#8B4513" />
      </mesh>
      <mesh position={[0.5, -0.05, 0.03]}>
        <boxGeometry args={[0.1, 2.2, 0.3]} />
        <meshStandardMaterial color="#8B4513" />
      </mesh>

      {/* Add some books */}
      {/* top shelf */}
      
        {/* <Book position={[-0.2, 0.65, 0.07]} rotation={[0, Math.PI / 2, 0]} color="#FF00FF" /> */}
        {/* <Book position={[-0.15, 0.65, 0.07]} rotation={[0, Math.PI / 2, 0]} color="#00FFFF" /> */}
        {/* <Book position={[-0.1, 0.65, 0.07]} rotation={[0, Math.PI / 2, 0]} color="#FFA500" /> */}
        <Book position={[-0.05, 0.65, 0.07]} rotation={[0, Math.PI / 2, 0]} color="#800080" />
        <Book position={[0, 0.65, 0.07]} rotation={[0, Math.PI / 2, 0]} color="#808080" />
        <Book position={[0.05, 0.65, 0.07]} rotation={[0, Math.PI / 2, 0]} color="#FF0000" />
        <Book position={[0.1, 0.65, 0.07]} rotation={[0, Math.PI / 2, 0]} color="#00FF00" />
        <Book position={[0.15, 0.65, 0.07]} rotation={[0, Math.PI / 2, 0]} color="#0000FF" />
        <Book position={[0.2, 0.65, 0.07]} rotation={[0, Math.PI / 2, 0]} color="#FFFF00" />
        <Book position={[0.25, 0.65, 0.07]} rotation={[0, Math.PI / 2, 0]} color="#FF00FF" />
        <Book position={[0.3, 0.65, 0.07]} rotation={[0, Math.PI / 2, 0]} color="#00FFFF" />
        <Book position={[0.35, 0.65, 0.07]} rotation={[0, Math.PI / 2, 0]} color="#FFA500" />
        <Book position={[0.4, 0.65, 0.07]} rotation={[0, Math.PI / 2, 0]} color="#800080" />
    {/* middle shelf */}
      <Book position={[-0.4, -0.03, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#FFFF00" />
      <Book position={[-0.35, -0.03, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#FF00FF" />
      <Book position={[-0.3, -0.03, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#00FFFF" />
        <Book position={[-0.25, -0.03, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#FFA500" />
        <Book position={[-0.2, -0.03, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#800080" />
        <Book position={[-0.15, -0.03, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#808080" />
        <Book position={[-0.1, -0.03, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#FF0000" />
        <Book position={[-0.05, -0.03, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#00FF00" />
        <Book position={[0, -0.03, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#0000FF" />
        <Book position={[0.05, -0.03, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#FFFF00" />
        <Book position={[0.1, -0.03, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#FF00FF" />
        <Book position={[0.15, -0.03, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#00FFFF" />
    {/* bottom shelf */}
      <Book position={[-0.4, -0.74, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#FFA500" />
      <Book position={[-0.35, -0.74, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#800080" />
      <Book position={[-0.3, -0.74, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#808080" />
        <Book position={[-0.25, -0.74, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#FF0000" />
        <Book position={[-0.2, -0.74, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#00FF00" />
        <Book position={[-0.15, -0.74, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#0000FF" />
        <Book position={[-0.1, -0.74, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#FFFF00" />
        <Book position={[-0.05, -0.74, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#FF00FF" />
        <Book position={[0, -0.74, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#00FFFF" />
        <Book position={[0.05, -0.74, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#FFA500" />
        <Book position={[0.1, -0.74, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#800080" />
        <Book position={[0.15, -0.74, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#808080" />
        <Book position={[0.2, -0.74, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#FF0000" />
        <Book position={[0.25, -0.74, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#00FF00" />
        <Book position={[0.3, -0.74, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#0000FF" />
        <Book position={[0.35, -0.74, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#FFFF00" />
        <Book position={[0.4, -0.74, 0.1]} rotation={[0, Math.PI / 2, 0]} color="#FF00FF" />
    </mesh>
  );
};

export default BookShelf;
