import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { ThemeProvider } from "./contexts/ThemeContext";
import MainMenu from "./components/MainMenu/MainMenu";
import Home from "./pages/Home/Home";
import Relax from "./pages/Relax/Relax";
import About from "./pages/About/About";
import Music from "./pages/Music/Music";
import Yoga from "./pages/Yoga/Yoga";
import Development from "./pages/Development/Development";
import Acting from "./pages/Acting/Acting";
import useTitle from "./hooks/useTitle";
import "normalize.css";
import "./App.css";

function App() {
  return (
    <Router>
      <ThemeProvider>
        <RouteRender />
      </ThemeProvider>
    </Router>
  );
}

function RouteRender() {
  const location = useLocation();
  useTitle();

  return (
    <AnimatePresence mode="wait">
      <MainMenu />
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/music" element={<Music />} />
        <Route path="/relax" element={<Relax />} />
        <Route path="/development" element={<Development />} />
        <Route path="/yoga" element={<Yoga />} />
        <Route path="/acting" element={<Acting />} />
      </Routes>
    </AnimatePresence>
  );
}

export default App;
