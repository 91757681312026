import { createContext, useContext, useState, useEffect, useRef } from 'react';
import { Vector3 } from 'three';
import { useFrame, useThree } from '@react-three/fiber';

const CameraContext = createContext();

export const CameraProvider = ({ children }) => {
  const { camera } = useThree();
  const [targetPosition, setTargetPosition] = useState(new Vector3(28.5, 10, 17));
  const [lookAtTarget, setLookAtTarget] = useState(new Vector3(0, 0, 0));
  const [isTransitioning, setIsTransitioning] = useState(false);
  const initialPositionSet = useRef(false);

  useEffect(() => {
    if (!initialPositionSet.current) {
      // Set initial camera position and orientation only once
      camera.position.set(28.5, 10, 17);
      camera.lookAt(lookAtTarget);
      initialPositionSet.current = true;
    }
  }, [camera, lookAtTarget]);

  useFrame(() => {
    if (isTransitioning) {
      const step = 0.05; // Reduced step size for smoother transition
      const distance = camera.position.distanceTo(targetPosition);

      if (distance > 0.01) { // Smaller threshold to ensure it stops closer to the target
        camera.position.lerp(targetPosition, step);
      } else {
        camera.position.copy(targetPosition);
        setIsTransitioning(false);
      }
      // Ensure camera is looking at the target position
      camera.lookAt(lookAtTarget);
    } else {
      // Ensure camera maintains looking at the correct target after transition
      camera.lookAt(lookAtTarget);
    }
  });

  const updateCameraView = (position, target) => {
    setTargetPosition(new Vector3(...position));
    setLookAtTarget(new Vector3(...target));
    setIsTransitioning(true);
  };

  return (
    <CameraContext.Provider value={{ updateCameraView, isTransitioning }}>
      {children}
    </CameraContext.Provider>
  );
};

export const useCamera = () => useContext(CameraContext);
