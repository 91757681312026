import { OrbitControls } from '@react-three/drei';
import Desk from '../Desk/Desk';
import Chair from '../Chair/Chair';
import Computer from '../Computer/Computer';
import Keyboard from '../Keyboard/Keyboard';
import Lamp from '../Lamp/Lamp';
import Plant from '../Plant/Plant';
import CoffeeTable from '../CoffeeTable/CoffeeTable';
import RecordPlayer from '../RecordPlayer/RecordPlayer';
import Speaker from '../Speaker/Speaker';
import CoffeeMug from '../CoffeeMug/CoffeeMug';
import Clock from '../Clock/Clock';
import YogaMat from '../YogaMat/YogaMat';
import Art from '../Art/Art';
import BookShelf from '../BookShelf/BookShelf';
import Sofa from '../Sofa/Sofa';
import TV from '../TV/TV';
import Buddha from '../../../assets/buddha.jpg';
import womanMeditating from '../../../assets/womanMeditating.webp';
import womanDrinkingWine from '../../../assets/womanDrinkingWine.webp';
import Armchair from '../Armchair/Armchair';
import GlassTable from '../GlassTable/GlassTable';
import Piano from '../Piano/Piano';
import Puppy from '../Puppy/Puppy';
import Camera from '../Camera/Camera';
import CielingFan from '../CielingFan/CielingFan';
import FirePlace from '../FIrePlace/FirePlace';
import ReadingNookFloor from '../ReadingNook/ReadingNookFloor';
import LightSettings from './LightSettings';
import Wall from './Wall';
import MiniWall from './MiniWall';
import WindowPane from './WindowPane';
import Floor from './Floor';
// import { useCamera } from '../../contexts/CameraContext';

const getLightSettings = (currentTime) => {
  let windowLightColor = "#FFA07A";
  let windowLightIntensity = 0.5;

  if (currentTime >= 5 && currentTime < 7) {
    windowLightColor = "#FFD700"; // Dawn
    windowLightIntensity = 0.6;
  } else if (currentTime >= 7 && currentTime < 10) {
    windowLightColor = "#FFFACD"; // Morning
    windowLightIntensity = 0.8;
  } else if (currentTime >= 10 && currentTime < 14) {
    windowLightColor = "#FFFFFF"; // Noon
    windowLightIntensity = 1.0;
  } else if (currentTime >= 14 && currentTime < 17) {
    windowLightColor = "#FF8C00"; // Afternoon
    windowLightIntensity = 0.9;
  } else if (currentTime >= 17 && currentTime < 19) {
    windowLightColor = "#FF4500"; // Evening
    windowLightIntensity = 0.7;
  } else {
    windowLightColor = "#191970"; // Night
    windowLightIntensity = 0.4;
  }

  return { windowLightColor, windowLightIntensity };
};

const RoomContent = ({ currentTime }) => {
  const { windowLightColor, windowLightIntensity } = getLightSettings(currentTime);
  // const { updateCameraView, isTransitioning } = useCamera();

  // const handleCameraView = (position, target) => {
  //   updateCameraView(position, target);
  // };

  return (
    <>
      <LightSettings windowLightColor={windowLightColor} windowLightIntensity={windowLightIntensity} />
      <OrbitControls />
      <Floor color="#72675F" />
      {/* Wall with fire place */}
      <Wall position={[-0.1, 0.5, -5.75]} rotation={[0, 0, 0]} scale={[1.07, 1, 1]} color="#D5D8D1" />
      <Wall position={[14.7, 0.5, -5.75]} rotation={[0, 0, 0]} scale={[0.46, 1, 1]} color="#D5D8D1" />
      <Wall position={[9, 1.4, -5.75]} rotation={[0, 0, 0]} scale={[0.6, 0.7, 1]} color="#D5D8D1" />
      <Wall position={[9, -1.2, -5.925]} rotation={[0, 0, 0]} scale={[0.6, 0.5, 0.3]} color="#D5D8D1" />
      <Wall position={[9, -2.35, -5.575]} rotation={[0, 0, 0]} scale={[0.6, 0.05, 0.3]} color="#D5D8D1" />
      {/* Wall with window */}
      <MiniWall position={[-5.78, 2.77, 0.8]} rotation={[0, Math.PI / 2, 0]} scale={[3.581, 1.2, 0.75]} color="#D5D8D1" />
      <MiniWall position={[-5.78, -2, 0.8]} rotation={[0, Math.PI / 2, 0]} scale={[3.581, 1.2, 0.75]} color="#D5D8D1" />
      <MiniWall position={[-5.78, 0.5, -3.5]} rotation={[0, Math.PI / 2, 0]} scale={[2, 2, 0.75]} color="#D5D8D1" />
      <MiniWall position={[-5.78, 0.5, 4.9]} rotation={[0, Math.PI / 2, 0]} scale={[2.15, 2, 0.75]} color="#D5D8D1" />
      <MiniWall position={[-5.78, -1.2, 0.59]} rotation={[0, Math.PI / 2, 0]} scale={[2.04, 0.4, 0.85]} color="#ffffff" />
      <MiniWall position={[-5.78, 1.98, 0.59]} rotation={[0, Math.PI / 2, 0]} scale={[2.04, 0.4, 0.85]} color="#ffffff" />
      <MiniWall position={[-5.78, 0.4, -1.41]} rotation={[0, Math.PI / 2, 0]} scale={[0.4, 1.75, 0.85]} color="#ffffff" />
      <MiniWall position={[-5.78, 0.4, 2.59]} rotation={[0, Math.PI / 2, 0]} scale={[0.4, 1.75, 0.85]} color="#ffffff" />
      <group position={[-3.75, 0, -3.5]} rotation={[0, 0.8, 0]}>
        <WindowPane position={[-5.78, 0.4, 2.49]} rotation={[0, Math.PI / 2, 0]} scale={[0.3, 1.75, 0.5]} color="#ccc" castShadow />
        <WindowPane position={[-5.78, 0.39, 0.85]} rotation={[0, Math.PI / 2, 0]} scale={[0.3, 1.74, 0.5]} color="#ccc" />
        <WindowPane position={[-5.78, 1.86, 1.69]} rotation={[0, Math.PI / 2, 0]} scale={[1.3, 0.3, 0.5]} color="#ccc" />
        <WindowPane position={[-5.78, -1.08, 1.69]} rotation={[0, Math.PI / 2, 0]} scale={[1.3, 0.3, 0.5]} color="#ccc" />
      </group>
      <group position={[-11.75, 0, -3.6]} rotation={[0, -0.8, 0]}>
        <WindowPane position={[5.78, 0.4, -2.49]} rotation={[0, -Math.PI / 2, 0]} scale={[0.3, 1.75, 0.5]} color="#ccc" />
        <WindowPane position={[5.78, 0.39, -0.85]} rotation={[0, -Math.PI / 2, 0]} scale={[0.3, 1.74, 0.5]} color="#ccc" />
        <WindowPane position={[5.78, 1.86, -1.69]} rotation={[0, -Math.PI / 2, 0]} scale={[1.3, 0.3, 0.5]} color="#ccc" />
        <WindowPane position={[5.78, -1.08, -1.69]} rotation={[0, -Math.PI / 2, 0]} scale={[1.3, 0.3, 0.5]} color="#ccc" />
      </group>
      {/* Reading nook */}
      <MiniWall position={[-5.78, 0.49, 11.5]} rotation={[0, Math.PI / 2, 0]} scale={[0.5, 2.45, 0.75]} color="#D5D8D1" />
      <MiniWall position={[-5.78, 3.31, 9.3]} rotation={[0, Math.PI / 2, 0]} scale={[2.05, 0.6, 0.75]} color="#D5D8D1" />
      <WindowPane position={[-5.78, 3, 9.3]} rotation={[0, -Math.PI / 2, 0]} scale={[2.04, 0.5, 0.8]} color="#000" />
      <WindowPane position={[-5.78, 0.18, 11.3]} rotation={[0, -Math.PI / 2, 0]} scale={[0.4, 2.33, 0.8]} color="#000" />
      <WindowPane position={[-5.78, 0.18, 7.3]} rotation={[0, -Math.PI / 2, 0]} scale={[0.4, 2.33, 0.8]} color="#000" />
      <WindowPane position={[-7.55, 2.82, 9.3]} rotation={[0, -Math.PI / 2, 0]} scale={[1.7, 0.4, 0.3]} color="#000" castShadow />
      <WindowPane position={[-7.55, 1.1, 9.3]} rotation={[0, -Math.PI / 2, 0]} scale={[1.7, 0.3, 0.3]} color="#000" castShadow />
      <WindowPane position={[-7.55, -0.7, 9.3]} rotation={[0, -Math.PI / 2, 0]} scale={[1.7, 0.3, 0.3]} color="#000" castShadow />
      <WindowPane position={[-7.55, -2.418, 9.3]} rotation={[0, -Math.PI / 2, 0]} scale={[1.7, 0.4, 0.3]} color="#000" castShadow />
      <group position={[-6.75, 0, 5]} rotation={[0, 1.3, 0]}>
        <WindowPane position={[-5.78, 0.2, 0.82]} rotation={[0, Math.PI / 2, 0]} scale={[0.3, 2.326, 0.5]} color="#000" castShadow />
        <WindowPane position={[-5.78, 2.86, 1.65]} rotation={[0, Math.PI / 2, 0]} scale={[1.3, 0.3, 0.5]} color="#000" castShadow />
        <WindowPane position={[-5.78, 1.1, 1.65]} rotation={[0, Math.PI / 2, 0]} scale={[1.3, 0.3, 0.5]} color="#000" castShadow />
        <WindowPane position={[-5.78, -0.7, 1.65]} rotation={[0, Math.PI / 2, 0]} scale={[1.3, 0.3, 0.5]} color="#000" castShadow />
        <WindowPane position={[-5.78, -2.46, 1.82]} rotation={[0, Math.PI / 2, 0]} scale={[1.44, 0.3, 0.5]} color="#000" castShadow />
      </group>
      <group position={[-5, 0, 6.35]} rotation={[0, -1.3, 0]}>
        <WindowPane position={[0.75, 0.2, 2.82]} rotation={[0, Math.PI / 2, 0]} scale={[0.3, 2.326, 0.5]} color="#000" castShadow />
        <WindowPane position={[0.75, 2.86, 1.85]} rotation={[0, Math.PI / 2, 0]} scale={[1.4, 0.3, 0.5]} color="#000" castShadow />
        <WindowPane position={[0.75, 1.1, 1.85]} rotation={[0, Math.PI / 2, 0]} scale={[1.4, 0.3, 0.5]} color="#000" castShadow />
        <WindowPane position={[0.75, -0.7, 1.85]} rotation={[0, Math.PI / 2, 0]} scale={[1.4, 0.3, 0.5]} color="#000" castShadow />
        <WindowPane position={[0.75, -2.46, 1.75]} rotation={[0, Math.PI / 2, 0]} scale={[1.44, 0.3, 0.5]} color="#000" castShadow />
      </group>
      <Desk position={[-2, -2.45, -4]} castShadow />
      <Chair position={[-2, -2.45, -2]} castShadow />
      <Computer 
        position={[-2.95, -1.1, -4.5]} 
        rotation={[0, Math.PI / 18, 0]} 
        castShadow 
        // onClick={() => handleCameraView([-2.95, 0, -2], [-2.95, -1.1, -4.5])}
      />
      <Computer 
        position={[-1.05, -1.1, -4.5]} 
        rotation={[0, -Math.PI / 18, 0]} 
        castShadow
        // onClick={() => handleCameraView([-2.95, 0, -2], [-2.95, -1.1, -4.5])} 
        />
      <Keyboard position={[-2, -0.85, -3.75]} rotation={[0, 0, 0]} scale={[1, 1, 1]} castShadow />
      <Lamp position={[1.5, -2, -3.5]} castShadow />
      <Lamp position={[-5.8, -1.7, 9.2]} castShadow />
      <Plant position={[-4.7, -2.5, 3.7]} scale={[1, 1, 1]} castShadow />
      <CoffeeTable position={[4, -1.5, -4.5]} rotation={[0, 0, 0]} scale={[1, 1, 1]} color="#8B4513" castShadow />
      <RecordPlayer position={[4.45, -1.45, -4.5]} scale={[0.5, 0.5, 0.5]} rotation={[0, 1.55, 0]} castShadow />
      <Speaker position={[3.4, -1, -4.5]} rotation={[0, -0.2, 0]} scale={[0.5, 0.5, 0.5]} castShadow />
      <CoffeeMug position={[-0.2, -0.65, -3.5]} scale={[0.5, 0.5, 0.5]} rotation={[0, -0.5, 0]} castShadow />
      <Clock position={[4, 2.2, -5.45]} rotation={[0, 0, 0]} scale={[0.5, 0.5, 0.5]} castShadow />
      <YogaMat position={[2.8, -2.5, 1]} rotation={[0, 0, 0]} scale={[1, 1, 1]} color="#4682B4" />
      <Art 
        position={[-5.5, 1.6, -3.7]} 
        rotation={[0, Math.PI / 2, 0]} 
        scale={[1, 1, 1]} 
        textureImage={Buddha}
        // onClick={() => handleCameraView([0, 1.6, -3.7], [-5.5, 1.6, -3.7])} 
    />
      <Art 
        position={[15, 1.55, -5.45]} 
        rotation={[0, Math.PI, Math.PI / 2]} 
        scale={[1.7, 1.7, 1]} 
        textureImage={womanMeditating} 
        // onClick={() => handleCameraView([15, 2, 1], [15, 1.55, -5.45])}
    />
      <Art 
        position={[-5.5, 1.1, 3.75]} 
        rotation={[0, Math.PI / 2, 0]} 
        scale={[1.4, 1.4, 1]} 
        textureImage={womanDrinkingWine} 
        // onClick={() => handleCameraView([0, 1, 3.75], [-5.5, 1.1, 3.75])}
    />
      <BookShelf position={[-5.1, -0.3, 5.8]} rotation={[0, Math.PI / 2, 0]} scale={[1.9, 2, 2.5]} />
      <Sofa position={[9, -2.4, 5]} rotation={[0, Math.PI, 0]} scale={[1, 1, 1]} />
      <TV 
        position={[9, 1.4, -5.39]} 
        rotation={[0, 0, 0]} 
        scale={[2.2, 2.2, 1.2]} 
        // onClick={() => handleCameraView([9, 2, 2], [9, 1.4, -5.39])}
    />
      <Armchair position={[13.5, -2.4, 1]} rotation={[0, -Math.PI / 2, 0]} scale={[1, 1, 1]} />
      <GlassTable position={[9, -2.5, 1]} rotation={[0, 0, 0]} scale={[1, 1, 1]} />
      <Piano position={[14.5, -2.2, -4.8]} scale={[1, 1, 1]} />
      <Puppy position={[13.5, -2.5, 8]} rotation={[0, 1.2, 0]} />
      <Camera position={[-5, 0.65, 6.27]} rotation={[0, Math.PI / 4, 0]} scale={[0.6, 0.6, 0.6]} />
      <CielingFan position={[0, 4, 0]} rotation={[0, 0, 0]} scale={[2.5, 2.5, 2.5]} />
      <FirePlace position={[9, -1.5, -5.7]} rotation={[0, 0, 0]} scale={[1.3, 1.5, 1]} />
      <ReadingNookFloor position={[-6, -2.4, 9.3]} rotation={[0, 0, 0]} scale={[1, 1, 1]} color="#C2B280" />
      <ReadingNookFloor position={[-6, -2.75, 9.3]} rotation={[0, 0, 0]} scale={[1.1, 1.5, 1.1]} color="#72675F" />
    </>
  );
};

export default RoomContent;
