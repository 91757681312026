import { Canvas } from "@react-three/fiber";
import City from "./City";
import Lights from "./Lights";
import Particles from "./Particles";
import Cars from "./Cars";

export default function CityScene() {
  const color = "#171720";

  return (
    <Canvas
      shadows={{ enabled: true, type: "PCFSoftShadowMap" }}
      gl={{ antialias: true }}
      camera={{ position: [10, 7, 14], fov: 25 }}
      style={{ backgroundColor: color, display: "block", height: "100vh" }}
    >
      <fog attach="fog" args={[color, 5, 40]} />
      <Lights />
      <City />
      <Particles />
      <Cars />
    </Canvas>
  );
}
