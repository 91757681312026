import { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { RoundedBox } from '@react-three/drei';

const Puppy = ({ position, rotation }) => {
  const tailRef = useRef();
  const headGroupRef = useRef();
  const frontLeftLegRef = useRef();
  const frontRightLegRef = useRef();
  const backLeftLegRef = useRef();
  const backRightLegRef = useRef();
  const ballRef = useRef();

  useFrame((state, delta) => {
    if (tailRef.current) {
      tailRef.current.rotation.z = Math.sin(state.clock.getElapsedTime() * 5) * 0.3;
    }
    if (headGroupRef.current) {
      headGroupRef.current.position.y = 1.05 + Math.sin(state.clock.getElapsedTime() * 2) * 0.05;
      headGroupRef.current.rotation.y = Math.sin(state.clock.getElapsedTime() * 2) * 0.1;
    }
    const legRotation = Math.sin(state.clock.getElapsedTime() * 4) * 0.2;
    if (frontLeftLegRef.current) {
      frontLeftLegRef.current.rotation.x = legRotation;
    }
    if (frontRightLegRef.current) {
      frontRightLegRef.current.rotation.x = -legRotation;
    }
    if (backLeftLegRef.current) {
      backLeftLegRef.current.rotation.x = -legRotation;
    }
    if (backRightLegRef.current) {
      backRightLegRef.current.rotation.x = legRotation;
    }
    if (ballRef.current) {
      ballRef.current.position.x = Math.sin(state.clock.getElapsedTime()) * 0.5;
      ballRef.current.position.z = Math.cos(state.clock.getElapsedTime()) * 0.5;
    }
  });

  return (
    <group position={position} rotation={rotation}>
      {/* Body */}
      <RoundedBox args={[1, 0.5, 0.5]} radius={0.05} smoothness={4} position={[0, 0.5, -0.3]} rotation={[0, Math.PI / 2, 0]} castShadow>
        <meshStandardMaterial color="#D2B48C" />
      </RoundedBox>
      {/* Head Group */}
      <group ref={headGroupRef} position={[0, 1.05, 0.2]}>
        {/* Head */}
        <RoundedBox args={[0.4, 0.4, 0.4]} radius={0.05} smoothness={4} castShadow>
          <meshStandardMaterial color="#D2B48C" />
        </RoundedBox>
        {/* Snout */}
        <RoundedBox args={[0.2, 0.2, 0.3]} radius={0.05} smoothness={4} position={[0, -0.1, 0.25]} castShadow>
          <meshStandardMaterial color="#D2B48C" />
        </RoundedBox>
        {/* Nose */}
        <mesh position={[0, -0.05, 0.4]} castShadow>
          <sphereGeometry args={[0.07, 32, 32]} />
          <meshStandardMaterial color="#000000" />
        </mesh>
        {/* Ears */}
        <RoundedBox args={[0.1, 0.2, 0.05]} radius={0.02} smoothness={4} position={[-0.2, 0.1, -0.2]} rotation={[0, 0, Math.PI / 6]} castShadow>
          <meshStandardMaterial color="#8B4513" />
        </RoundedBox>
        <RoundedBox args={[0.1, 0.2, 0.05]} radius={0.02} smoothness={4} position={[0.2, 0.1, -0.2]} rotation={[0, 0, -Math.PI / 6]} castShadow>
          <meshStandardMaterial color="#8B4513" />
        </RoundedBox>
      </group>
      {/* Collar */}
      <mesh position={[0, 0.8, 0.15]} rotation={[Math.PI / 2, 0, 0]} castShadow>
        <torusGeometry args={[0.25, 0.05, 12, 100]} />
        <meshStandardMaterial color="#FF0000" />
      </mesh>
      {/* Legs */}
      <mesh ref={frontLeftLegRef} position={[-0.2, 0.2, 0]} castShadow>
        <cylinderGeometry args={[0.1, 0.1, 0.3, 32]} />
        <meshStandardMaterial color="#D2B48C" />
      </mesh>
      <mesh ref={frontRightLegRef} position={[0.2, 0.2, 0]} castShadow>
        <cylinderGeometry args={[0.1, 0.1, 0.3, 32]} />
        <meshStandardMaterial color="#D2B48C" />
      </mesh>
      <mesh ref={backLeftLegRef} position={[-0.2, 0.2, -0.6]} castShadow>
        <cylinderGeometry args={[0.1, 0.1, 0.3, 32]} />
        <meshStandardMaterial color="#D2B48C" />
      </mesh>
      <mesh ref={backRightLegRef} position={[0.2, 0.2, -0.6]} castShadow>
        <cylinderGeometry args={[0.1, 0.1, 0.3, 32]} />
        <meshStandardMaterial color="#D2B48C" />
      </mesh>
      {/* Tail */}
      <mesh ref={tailRef} position={[0, 0.7, -0.9]} rotation={[-Math.PI / 4, 0, 0]} castShadow>
        <cylinderGeometry args={[0.05, 0.05, 0.4, 32]} />
        <meshStandardMaterial color="#D2B48C" />
      </mesh>
      {/* Puppy Bowl */}
      <group position={[-1.2, 0.1, 0]}>
        <mesh castShadow>
          <cylinderGeometry args={[0.5, 0.5, 0.2, 32]} />
          <meshStandardMaterial color="#C0C0C0" />
        </mesh>
        <mesh position={[0, 0.1, 0]} castShadow>
          <cylinderGeometry args={[0.45, 0.45, 0.05, 32]} />
          <meshStandardMaterial color="#FFFFFF" />
        </mesh>
      </group>
      {/* Ball */}
      <mesh ref={ballRef} position={[0.5, 0.2, 0.5]} castShadow>
        <sphereGeometry args={[0.2, 32, 32]} />
        <meshStandardMaterial color="#FFA500" />
      </mesh>
    </group>
  );
};

export default Puppy;
