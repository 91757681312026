import { Canvas } from '@react-three/fiber';
import RoomContent from './RoomContent';
import { CameraProvider } from '../../../contexts/CameraContext';

const Room = ({ currentTime }) => {
  return (
    <Canvas shadows camera={{ fov: 85 }}>
      <CameraProvider>
        <RoomContent currentTime={currentTime} />
      </CameraProvider>
    </Canvas>
  );
};

export default Room;
