import headshotBW from '../../assets/headshotBW.webp';
import ContactForm from '../../components/ContactForm/ContactForm';
import Footer from '../../components/Footer/Footer';
import styles from './About.module.css';

const About = () => {
  const bioParagraph = `Jordan Olguin is a dedicated yogi, a passionate musician, a versatile actor, and a skilled full stack developer. With a rich background in both creative and technical fields, he excels at navigating the intersections of these diverse disciplines. From the serene focus of yoga to the electrifying energy of live music and the intricate art of coding, Jordan is committed to pushing boundaries and crafting experiences that resonate. Whether performing on stage, recording in the studio, or developing cutting-edge applications, Jordan Olguin brings a unique blend of artistry and precision to every project, consistently striving for excellence and growth.`;

  return (
    <>
    <div className={styles.aboutContainer}>
      <h1 className={styles.aboutTitle}>MEET JORDAN</h1>
      <img
        src={headshotBW}
        alt="Jordan Olguin"
        className={styles.aboutImage}
      />
      <p className={styles.aboutText}>{bioParagraph}</p>
      <ContactForm page="about" />
    </div>
    <Footer />
    </>
  );
};

export default About;
