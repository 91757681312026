import { useRef, useMemo } from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

const FirePlace = ({ position, rotation, scale }) => {
  const fireRef = useRef();
  const particlesRef = useRef();
  const stonesRef = useRef();
  const dummy = useMemo(() => new THREE.Object3D(), []);

  const count = 100;
  const stoneCount = 100;
  const particles = useMemo(() => {
    const temp = [];
    for (let i = 0; i < count; i++) {
      temp.push({
        position: [
          (Math.random() - 0.5) * 4, // Width of the fireplace
          Math.random() * 1.5, // Height of the fireplace
          (Math.random() - 0.5) * 0.5 // Depth of the fireplace
        ],
        scale: Math.random() * 0.5 + 0.1,
        speed: Math.random() * 0.02 + 0.01,
      });
    }
    return temp;
  }, [count]);

  const stones = useMemo(() => {
    const temp = [];
    for (let i = 0; i < stoneCount; i++) {
      temp.push({
        position: [
          (Math.random() - 0.5) * 4, // Width of the fireplace
          -0.2, // Fixed height just below the fireplace
          (Math.random() - 0.5) * 0.01 // Depth of the fireplace
        ],
        scale: Math.random() * 0.2 + 0.1,
      });
    }
    return temp;
  }, [stoneCount]);

  useFrame(() => {
    if (fireRef.current) {
      fireRef.current.material.emissiveIntensity = 1 + Math.sin(Date.now() / 100) * 0.5;
    }

    particles.forEach((particle, index) => {
      const { position, scale, speed } = particle;
      particle.position[1] += speed;
      if (particle.position[1] > 0.5) {
        particle.position[1] = 0;
      }
      dummy.position.set(...position);
      dummy.scale.setScalar(scale);
      dummy.updateMatrix();
      particlesRef.current.setMatrixAt(index, dummy.matrix);
    });
    particlesRef.current.instanceMatrix.needsUpdate = true;

    stones.forEach((stone, index) => {
      const { position, scale } = stone;
      dummy.position.set(...position);
      dummy.scale.setScalar(scale);
      dummy.updateMatrix();
      stonesRef.current.setMatrixAt(index, dummy.matrix);
    });
    stonesRef.current.instanceMatrix.needsUpdate = true;
  });

  return (
    <group position={position} rotation={rotation} scale={scale}>
      <mesh ref={fireRef}>
        <boxGeometry args={[5, 1, 0.1]} />
        <meshStandardMaterial color="#000000" />
        <pointLight color="#ff5500" intensity={2} distance={10} decay={2} />
        <pointLight color="#ff2200" intensity={1.5} distance={8} decay={2} />
        <pointLight color="#ffaa00" intensity={1} distance={6} decay={2} />

        <instancedMesh ref={particlesRef} args={[null, null, count]}>
          <sphereGeometry args={[0.1, 16, 16]} />
          <meshStandardMaterial color="#ff5500" emissive="#ff2200" emissiveIntensity={1} />
        </instancedMesh>
      </mesh>
      
      {/* Stones at the bottom */}
      <instancedMesh ref={stonesRef} args={[null, null, stoneCount]}>
        <sphereGeometry args={[0.5, 10, 10]} />
        <meshStandardMaterial color="#808080" />
      </instancedMesh>
      
      {/* Bottom Border */}
      <mesh position={[0, -0.5, 0.3]}>
        <boxGeometry args={[5.1, 0.1, 0.2]} />
        <meshStandardMaterial color="#444444" />
      </mesh>
      
      {/* Top Border */}
      <mesh position={[0, 0.55, 0.3]}>
        <boxGeometry args={[5.1, 0.1, 0.2]} />
        <meshStandardMaterial color="#444444" />
      </mesh>
    </group>
  );
};

export default FirePlace;
