import  { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

const Steam = () => {
  const steamGroup = useRef();
  
  useFrame(() => {
    steamGroup.current.children.forEach((steamParticle, index) => {
      steamParticle.position.y += 0.01;
      if (steamParticle.position.y > 2) {
        steamParticle.position.y = 0;
      }
    });
  });

  const steamParticles = Array.from({ length: 10 }, (_, index) => (
    <mesh key={index} position={[Math.random() * 0.5 - 0.25, Math.random() * 0.5, Math.random() * 0.5 - 0.25]}>
      <sphereGeometry args={[0.05, 16, 16]} />
      <meshStandardMaterial color="#ffffff" transparent opacity={0.5} />
    </mesh>
  ));

  return <group ref={steamGroup}>{steamParticles}</group>;
};

const CoffeeMug = ({ position, scale, rotation }) => {
  return (
    <group position={position} scale={scale} rotation={rotation}>
      {/* Outer Mug */}
      <mesh>
        <cylinderGeometry args={[0.4, 0.4, 0.7, 32, 1, true]} />
        <meshStandardMaterial color="#ffffff" />
      </mesh>

      {/* Inner Mug */}
      <mesh position={[0, 0.05, 0]}>
        <cylinderGeometry args={[0.45, 0.45, 0.6, 32]} />
        <meshStandardMaterial color="#ffffff" />
      </mesh>

      {/* Handle */}
      <mesh position={[0.5, 0.05, 0]}>
        <torusGeometry args={[0.2, 0.06, 6, 100]} />
        <meshStandardMaterial color="#ffffff" />
      </mesh>

      {/* Coaster */}
      <mesh position={[0, -0.35, 0]}>
        <cylinderGeometry args={[0.65, 0.65, 0.06, 32]} />
        <meshStandardMaterial color="#b08d57" />
      </mesh>

      {/* Coffee Surface */}
      <mesh position={[0, 0.35, 0]}>
        <cylinderGeometry args={[0.44, 0.44, 0.02, 32]} />
        <meshStandardMaterial color="#6f4e37" />
      </mesh>

      {/* Steam */}
      <Steam />
    </group>
  );
};

export default CoffeeMug;
