import { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import styles from './Yoga.module.css';

const SectionComponent = ({ isLeftAligned, image, text }) => {
  const ref = useRef(null);
  const inView = useInView(ref, { triggerOnce: true });

  return (
    <motion.div
      className={styles.yogaSection}
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 1 : 0 }}
      transition={{ duration: 0.8 }}
      ref={ref}
    >
      {isLeftAligned ? (
        <>
          <div className={styles.leftSection}>
            <img src={image} alt="Yoga" className={styles.yogaImage} />
          </div>
          <div className={styles.rightSection}>
            <p className={styles.yogaText}>{text}</p>
          </div>
        </>
      ) : (
        <>
          <div className={styles.rightSection}>
            <p className={styles.yogaText}>{text}</p>
          </div>
          <div className={styles.leftSection}>
            <img src={image} alt="Yoga" className={styles.yogaImage} />
          </div>
        </>
      )}
    </motion.div>
  );
};

export default SectionComponent;
