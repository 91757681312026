import { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

const RecordPlayer = ({ position, scale, rotation }) => {
  const strobeDotsRef = useRef();

  useFrame(() => {
    if (strobeDotsRef.current) {
      strobeDotsRef.current.rotation.y -= 0.01;
    }
  });

  return (
    <group position={position} scale={scale} rotation={rotation}>
      {/* Base */}
      <mesh position={[0, 0.1, 0]}>
        <boxGeometry args={[2, 0.2, 2]} />
        <meshStandardMaterial color="#D2042D" />
      </mesh>
      {/* Turntable */}
      <mesh position={[0, 0.2, 0]}>
        <cylinderGeometry args={[0.9, 0.9, 0.04, 32]} />
        <meshStandardMaterial color="#333333" />
      </mesh>
      {/* Center Label */}
      <mesh position={[0, 0.25, 0]}>
        <cylinderGeometry args={[0.2, 0.2, 0.04, 32]} />
        <meshStandardMaterial color="#FF6347" />
      </mesh>
      {/* Tonearm Base */}
      <mesh position={[0.8, 0.25, 0.8]}>
        <boxGeometry args={[0.2, 0.1, 0.2]} />
        <meshStandardMaterial color="#555555" />
      </mesh>
      {/* Tonearm */}
      <mesh position={[0.4, 0.3, 0.8]} rotation={[0, 0, Math.PI / 2]}>
        <cylinderGeometry args={[0.02, 0.02, 0.8, 32]} />
        <meshStandardMaterial color="#f5f5f5" />
      </mesh>
      {/* Strobe Dots */}
      <group ref={strobeDotsRef}>
        {[...Array(45)].map((_, i) => (
          <mesh
            key={i}
            position={[
              0.85 * Math.cos((i * 2 * Math.PI) / 45),
              0.22,
              0.85 * Math.sin((i * 2 * Math.PI) / 45),
            ]}
          >
            <cylinderGeometry args={[0.01, 0.01, 0.01, 32]} />
            <meshStandardMaterial color="#FFFFFF" />
          </mesh>
        ))}
      </group>
    </group>
  );
};

export default RecordPlayer;
