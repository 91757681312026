const Key = ({ position, size }) => (
  <mesh position={position}>
    <boxGeometry args={size} />
    <meshStandardMaterial color="white" />
  </mesh>
);

const Keyboard = ({ position, rotation, scale }) => {
  const keys = [];
  const keySize = [0.1, 0.05, 0.1];
  const spaceBarSize = [0.6, 0.05, 0.1];

  for (let i = 0; i < 11; i++) {
    for (let j = 0; j < 3; j++) { 
      keys.push(
        <Key
          key={`key-${i}-${j}`}
          position={[-0.65 + i * 0.13, 0.05, -0.215 + j * 0.15]}
          size={keySize}
        />
      );
    }
  }

  keys.push(
    <Key
      key="space-bar"
      position={[0, 0.05, 0.22]}
      size={spaceBarSize}
    />
  );

  return (
    <mesh position={position} rotation={rotation} scale={scale}>
      <boxGeometry args={[1.5, 0.1, 0.6]} />
      <meshStandardMaterial color="black" />
      {keys}
    </mesh>
  );
};

export default Keyboard;
