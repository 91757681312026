const Computer = ({ position, rotation, onClick }) => {
  return (
    <group position={position} rotation={rotation}>
      {/* Monitor */}
      <mesh position={[0, 1.2, 0]} onPointerDown={onClick}>
        <boxGeometry args={[1.8, 1.2, 0.1]}/>
        <meshStandardMaterial color="#c0c0c0" />
      </mesh>
      {/* Screen */}
      <mesh position={[0, 1.2, 0.05]} onPointerDown={onClick}>
        <boxGeometry args={[1.6, 1, 0.05]}/>
        <meshStandardMaterial color="#000000" />
      </mesh>
      {/* Stand */}
      <mesh position={[0, 0.5, 0]} onPointerDown={onClick}>
        <boxGeometry args={[0.2, 0.3, 0.2]} />
        <meshStandardMaterial color="#ccc" />
      </mesh>
      {/* Base */}
      <mesh position={[0, 0.3, 0]} onPointerDown={onClick}>
        <boxGeometry args={[0.6, 0.1, 0.6]} />
        <meshStandardMaterial color="#ccc" />
      </mesh>
      {/* Pink-ish Back Glow */}
      <pointLight
        position={[0, 1.2, -0.2]}
        intensity={0.5}
        color="#ff69b4"
        distance={5}
        decay={2}
      />
      {/* Soft Screen Glow */}
      <pointLight
        position={[0, 1.2, 0.2]}
        intensity={0.3}
        color="#00ffff"
        distance={5}
        decay={2}
      />
    </group>
  );
};

export default Computer;
