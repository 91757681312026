import { useRef, useEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import gsap from "gsap";

function Cars() {
  const { scene } = useThree();
  const carsRef = useRef();

  useEffect(() => {
    const cars = new THREE.Group();
    const carLength = 3;
    const carMaterial = new THREE.LineBasicMaterial({
      color: 0xffff00,
      linewidth: 1,
    });

    const numCars = 60;
    const pathLength = 30;

    for (let i = 0; i < numCars; i++) {
      const isHorizontal = i % 2 === 0;
      const carGeometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(0, 0, 0),
        new THREE.Vector3(
          isHorizontal ? carLength : 0,
          0,
          isHorizontal ? 0 : carLength
        ),
      ]);
      const line = new THREE.Line(carGeometry, carMaterial);

      line.position.x = isHorizontal
        ? -pathLength / 2
        : THREE.MathUtils.randFloatSpread(30);
      line.position.z = isHorizontal
        ? THREE.MathUtils.randFloatSpread(30)
        : -pathLength / 2;
      line.position.y = Math.abs(THREE.MathUtils.randFloatSpread(9));

      cars.add(line);

      gsap.to(line.position, {
        [isHorizontal ? "x" : "z"]: pathLength,
        repeat: -1,
        yoyo: true,
        duration: 5 + Math.random() * 5,
        ease: "power1.inOut",
      });
    }

    scene.add(cars);
    carsRef.current = cars;

    return () => {
      scene.remove(cars);
    };
  }, [scene]);

  useFrame(() => {
    if (carsRef.current) {
      carsRef.current.rotation.y += 0.001;
    }
  });

  return null;
}

export default Cars;
