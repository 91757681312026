import { useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { Cylinder, Sphere, RoundedBox } from '@react-three/drei';

const Chair = ({ position }) => {
  const swivelRef = useRef();
  const [direction, setDirection] = useState(1);
  const [currentRotation, setCurrentRotation] = useState(0);

  useFrame(() => {
    if (swivelRef.current) {
      const maxRotation = 0.5;
      const speed = 0.006;

      let newRotation = currentRotation + speed * direction;
      if (newRotation > maxRotation || newRotation < -maxRotation) {
        setDirection(-direction);
        newRotation = currentRotation + speed * -direction;
      }

      swivelRef.current.rotation.y = newRotation;
      setCurrentRotation(newRotation);
    }
  });

  return (
    <>
      <group ref={swivelRef} position={position}>
        {/* Chair Seat */}
        <RoundedBox args={[1.2, 0.2, 1]} radius={0.1} smoothness={4} position={[0, 1, 0]}>
          <meshStandardMaterial attach="material" color="#495e7b" />
        </RoundedBox>
        {/* Chair Backrest */}
        <RoundedBox args={[1.2, 1.5, 0.2]} radius={0.1} smoothness={4} position={[0, 1.9, 0.4]}>
          <meshStandardMaterial attach="material" color="#495e7b" />
        </RoundedBox>
        {/* Armrests */}
        <RoundedBox args={[0.1, 0.1, 1.2]} radius={0.05} smoothness={4} position={[-0.75, 1.3, 0]}>
          <meshStandardMaterial attach="material" color="black" />
        </RoundedBox>
        <RoundedBox args={[0.1, 0.1, 1.2]} radius={0.05} smoothness={4} position={[0.75, 1.3, 0]}>
          <meshStandardMaterial attach="material" color="black" />
        </RoundedBox>
        {/* Armrest Padding */}
        <RoundedBox args={[0.15, 0.1, 1.2]} radius={0.05} smoothness={4} position={[-0.75, 1.35, 0]}>
          <meshStandardMaterial attach="material" color="gray" />
        </RoundedBox>
        <RoundedBox args={[0.15, 0.1, 1.2]} radius={0.05} smoothness={4} position={[0.75, 1.35, 0]}>
          <meshStandardMaterial attach="material" color="gray" />
        </RoundedBox>
        {/* Chair Base */}
        <Cylinder args={[0.1, 0.1, 0.7, 32]} position={[0, 0.6, 0]}>
          <meshStandardMaterial attach="material" color="black" />
        </Cylinder>
        <Cylinder args={[0.2, 0.2, 0.15, 32]} position={[0, 0.9, 0]}>
          <meshStandardMaterial attach="material" color="black" />
        </Cylinder>
        <Cylinder args={[0.2, 0.2, 0.1, 32]} position={[0, 0.2, 0]}>
          <meshStandardMaterial attach="material" color="black" />
        </Cylinder>
        <Cylinder args={[0.13, 0.13, 0.03, 32]} position={[0, 0.3, 0]}>
          <meshStandardMaterial attach="material" color="black" />
        </Cylinder>
      </group>
      <group position={[-2, -2.27, -2]}>
        {/* Chair Legs */}
        <Cylinder args={[0.05, 0.05, 0.6, 32]} position={[-0.5, 0, 0]} rotation={[0, 0, 1.57]}>
          <meshStandardMaterial attach="material" color="black" />
        </Cylinder>
        <Cylinder args={[0.05, 0.05, 0.6, 32]} position={[0.5, 0, 0]} rotation={[0, 0, 1.57]}>
          <meshStandardMaterial attach="material" color="black" />
        </Cylinder>
        <Cylinder args={[0.05, 0.05, 0.6, 32]} position={[0, 0, -0.5]} rotation={[1.57, 0, 0]}>
          <meshStandardMaterial attach="material" color="black" />
        </Cylinder>
        <Cylinder args={[0.05, 0.05, 0.6, 32]} position={[0, 0, 0.5]} rotation={[1.57, 0, 0]}>
          <meshStandardMaterial attach="material" color="black" />
        </Cylinder>
        {/* Wheels */}
        <group position={[0, -0.15, 0]}>
          <Sphere args={[0.1, 32, 32]} position={[-0.6, 0, 0]}>
            <meshStandardMaterial attach="material" color="black" />
          </Sphere>
          <Sphere args={[0.1, 32, 32]} position={[0.6, 0, 0]}>
            <meshStandardMaterial attach="material" color="black" />
          </Sphere>
          <Sphere args={[0.1, 32, 32]} position={[0, 0, -0.6]}>
            <meshStandardMaterial attach="material" color="black" />
          </Sphere>
          <Sphere args={[0.1, 32, 32]} position={[0, 0, 0.6]}>
            <meshStandardMaterial attach="material" color="black" />
          </Sphere>
        </group>
      </group>
    </>
  );
};

export default Chair;
