import { RoundedBox } from '@react-three/drei';

const Speaker = ({ position, rotation, scale }) => {
  return (
    <mesh position={position} rotation={rotation} scale={scale}>
      {/* Speaker box */}
      <RoundedBox args={[1, 2, 1]} radius={0.1} smoothness={4}>
        <meshStandardMaterial color="#D300E7" />  
      </RoundedBox>

      {/* Top speaker cone */}
      <mesh position={[0, 0.5, 0.51]} rotation={[Math.PI / 2, 0, 0]}>
        <cylinderGeometry args={[0.4, 0.4, 0.2, 32]} />
        <meshStandardMaterial color="black" />
      </mesh>

      {/* Bottom speaker cone */}
      <mesh position={[0, -0.4, 0.51]} rotation={[Math.PI / 2, 0, 0]}>
        <cylinderGeometry args={[0.45, 0.45, 0.2, 32]} />
        <meshStandardMaterial color="black" />
      </mesh>
    </mesh>
  );
};

export default Speaker;
