import styles from './Yoga.module.css';
import SectionComponent from './SectionComponent';
import ContactForm from '../../components/ContactForm/ContactForm';
import Footer from '../../components/Footer/Footer'

import yogaDandasana from '../../assets/yoga/yogaDandasana.webp';
import swamiji from '../../assets/yoga/swamiji.webp';
import kukkutasana from '../../assets/yoga/kukkutasana.webp';

const content = [
  {
    text: "Jordan Olguin is a devout yogi, trained in the Classic 26 postures, Ashtanga Yoga Asana, and pranayama. With a deep commitment to daily practice, he embodies the physical and spiritual discipline that yoga requires. His journey into yoga began with a fascination for the art of movement, and has since evolved into a profound exploration of the mind-body connection.",
    image: yogaDandasana,
  },
  {
    text: "Initiated into Kriya Yoga, Jordan carries forward an authentic lineage of masters, including Paramahansa Yogananda, Swami Sri Yukteshwar, and Mahavatar Babaji Maharaj. His practice is deeply rooted in the teachings and techniques passed down through generations, focusing on the union of breath and spirit.",
    image: swamiji,
  },
  {
    text: "Philosophically, Jordan's ideologies are influenced by the timeless wisdom of the Bhagavad Gita, The Tao Te Ching, and The Yoga Sutras of Patanjali. He integrates these teachings into his life, not just on the mat, but in every aspect of his existence, striving to live a life of balance, peace, and inner harmony.",
    image: kukkutasana,
  }
];

const mantras = [
  "- ॐ नमः शिवाय -",
  "- ॐ नमो गुरु देव् नमो -",
];

const Yoga = () => {
  return (
    <>
    <div className={styles.yogaContainer}>
      {content.map((item, index) => {
        const isLeftAligned = index % 2 === 0;
        return (
          <div key={index}>
            <SectionComponent
              isLeftAligned={isLeftAligned}
              image={item.image}
              text={item.text}
            />
            {index < mantras.length && (
              <div className={styles.mantraBorder}>{mantras[index]}</div>
            )}
          </div>
        );
      })}
      <ContactForm page="yoga" />
    </div>
    <Footer />
    </>
  );
};

export default Yoga;
