import { useLoader } from '@react-three/fiber';
import * as THREE from 'three';

const Art = ({ position, rotation, scale, textureImage, onClick }) => {
  const texture = useLoader(THREE.TextureLoader, textureImage);

  // Calculate the aspect ratio of the texture
  const aspectRatio = texture.image.width / texture.image.height;
  let geometryArgs;
  
  if (aspectRatio > 1) {
    // Landscape
    geometryArgs = [2, 2 / aspectRatio, 0.1];
  } else if (aspectRatio < 1) {
    // Portrait
    geometryArgs = [2 * aspectRatio, 2, 0.1];
  } else {
    // Square
    geometryArgs = [2, 2, 0.1];
  }

  return (
    <group position={position} rotation={rotation} scale={scale}>
      <mesh onPointerDown={onClick}>
        <boxGeometry args={geometryArgs} />
        <meshStandardMaterial map={texture} emissive="#ffffff" emissiveIntensity={0.035} />
      </mesh>
      {/* Top Frame */}
      <mesh position={[0, geometryArgs[1] / 2 + 0.05, 0]}>
        <boxGeometry args={[geometryArgs[0] + 0.2, 0.1, 0.1]} />
        <meshStandardMaterial color="#8B4513" />
      </mesh>
      {/* Bottom Frame */}
      <mesh position={[0, -geometryArgs[1] / 2 - 0.05, 0]}>
        <boxGeometry args={[geometryArgs[0] + 0.2, 0.1, 0.1]} />
        <meshStandardMaterial color="#8B4513" />
      </mesh>
      {/* Left Frame */}
      <mesh position={[-geometryArgs[0] / 2 - 0.05, 0, 0]}>
        <boxGeometry args={[0.1, geometryArgs[1], 0.1]} />
        <meshStandardMaterial color="#8B4513" />
      </mesh>
      {/* Right Frame */}
      <mesh position={[geometryArgs[0] / 2 + 0.05, 0, 0]}>
        <boxGeometry args={[0.1, geometryArgs[1], 0.1]} />
        <meshStandardMaterial color="#8B4513" />
      </mesh>
    </group>
  );
};

export default Art;
