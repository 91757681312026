import { useRef, useEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";

function City() {
  const cityRef = useRef();
  const { scene, gl } = useThree();

  const mathRandom = (num = 8) => -Math.random() * num + Math.random() * num;

  useEffect(() => {
    const city = new THREE.Group();
    const floorMaterial = new THREE.MeshPhongMaterial({
      color: 0x101010,
      side: THREE.DoubleSide,
    });
    const floorGeometry = new THREE.PlaneGeometry(60, 60);
    const floor = new THREE.Mesh(floorGeometry, floorMaterial);
    floor.rotation.x = -Math.PI / 2;
    floor.receiveShadow = true;
    city.add(floor);

    const gridHelper = new THREE.GridHelper(60, 120);
    gridHelper.position.y = 0.01;
    city.add(gridHelper);

    const gridSpacing = 1.5;
    const gridSize = 20;

    for (let i = 0; i < gridSize; i++) {
      for (let j = 0; j < gridSize; j++) {
        const buildingHeight = mathRandom(2) * 2 + 1;
        const geometry = new THREE.BoxGeometry(1, buildingHeight, 1);
        const material = new THREE.MeshStandardMaterial({
          color: 0x333333,
          roughness: 0.1,
          metalness: 1,
          side: THREE.DoubleSide,
        });
        const cube = new THREE.Mesh(geometry, material);

        const wireframeMaterial = new THREE.MeshBasicMaterial({
          color: 0xffffff,
          wireframe: true,
          transparent: true,
          opacity: 0.1,
        });
        const wireframe = new THREE.Mesh(geometry, wireframeMaterial);
        cube.add(wireframe);

        cube.position.x = (i - gridSize / 2) * gridSpacing + mathRandom(0.5);
        cube.position.y = buildingHeight / 2;
        cube.position.z = (j - gridSize / 2) * gridSpacing + mathRandom(0.5);

        cube.castShadow = true;
        city.add(cube);
      }
    }

    gl.shadowMap.enabled = true;
    scene.add(city);
    cityRef.current = city;

    return () => {
      scene.remove(city);
    };
  }, [scene, gl]);

  useFrame(() => {
    if (cityRef.current) {
      cityRef.current.rotation.y += 0.001;
    }
  });

  return null;
}

export default City;
