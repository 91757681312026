import { useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import tvImage from "../../../assets/tvImage.webp";

const TV = ({ position, rotation, scale, onClick }) => {
  const texture = useLoader(THREE.TextureLoader, tvImage);
  
  return (
    <group position={position} rotation={rotation} scale={scale}>
      {/* TV Frame */}
      <mesh castShadow receiveShadow>
        <boxGeometry args={[2.2, 1.2, 0.15]} />
        <meshStandardMaterial color="#000000" />
      </mesh>
      {/* TV Screen */}
      <mesh position={[0, 0, 0.06]} onPointerDown={onClick} castShadow receiveShadow>
        <boxGeometry args={[2, 1, 0.1]} />
        <meshStandardMaterial color="#333333" />
        <meshStandardMaterial map={texture} emissive="#ffffff" emissiveIntensity={0.02} />
      </mesh>
      {/* TV Buttons */}
      <mesh position={[1.05, -0.4, 0.08]} castShadow receiveShadow>
        <boxGeometry args={[0.04, 0.1, 0.02]} />
        <meshStandardMaterial color="#ccc" />
      </mesh>
      <mesh position={[1.05, -0.25, 0.08]} castShadow receiveShadow>
        <boxGeometry args={[0.04, 0.1, 0.02]} />
        <meshStandardMaterial color="#ccc" />
      </mesh>
      <mesh position={[1.05, -0.1, 0.08]} castShadow receiveShadow>
        <boxGeometry args={[0.04, 0.1, 0.02]} />
        <meshStandardMaterial color="#ccc" />
      </mesh>
      {/* TV Speakers */}
      <mesh position={[-1.2, 0, 0.08]} castShadow receiveShadow>
        <boxGeometry args={[0.08, 0.8, 0.2]} />
        <meshStandardMaterial color="#444444" />
      </mesh>
      <mesh position={[1.2, 0, 0.08]} castShadow receiveShadow>
        <boxGeometry args={[0.08, 0.8, 0.2]} />
        <meshStandardMaterial color="#444444" />
      </mesh>
      <mesh position={[0, -0.75, 0.08]} castShadow receiveShadow>
        <boxGeometry args={[1.5, 0.2, 0.3]} />
        <meshStandardMaterial color="#444444" />
      </mesh>
 {/* Soft Pink Glow */}
 <pointLight position={[0, 0, -0.2]} color="#FFB6C1" intensity={6} distance={9} />
    </group>
  );
};

export default TV;
