import { RoundedBox } from '@react-three/drei';

const Sofa = ({ position, rotation, scale }) => {
  return (
    <mesh position={position} rotation={rotation} scale={scale} receiveShadow castShadow>
      <group>
        {/* Main couch base */}
        <RoundedBox position={[0, 0.5, 0]} args={[5, 1, 3]} radius={0.2} smoothness={4}>
          <meshStandardMaterial color="#6B8E8D" />
        </RoundedBox>
        {/* Left armrest */}
        <RoundedBox position={[-2.6, 1, 0.2]} args={[0.5, 1, 2.5]} radius={0.2} smoothness={4}>
          <meshStandardMaterial color="#6B8E8D" />
        </RoundedBox>
        {/* Right armrest */}
        <RoundedBox position={[2.6, 1, 0.2]} args={[0.5, 1, 2.5]} radius={0.2} smoothness={4}>
          <meshStandardMaterial color="#6B8E8D" />
        </RoundedBox>
        {/* Backrest */}
        <RoundedBox position={[0, 1.75, -1.25]} args={[5.5, 1.5, 0.5]} radius={0.2} smoothness={4}>
          <meshStandardMaterial color="#6B8E8D" />
        </RoundedBox>
        {/* Left cushion */}
        <RoundedBox position={[-1.65, 0.75, 0.5]} args={[1.7, 0.6, 2.3]} radius={0.2} smoothness={4}>
          <meshStandardMaterial color="#5A7A7A" />
        </RoundedBox>
        {/* Middle cushion */}
        <RoundedBox position={[0, 0.75, 0.5]} args={[1.7, 0.6, 2.3]} radius={0.2} smoothness={4}>
          <meshStandardMaterial color="#5A7A7A" />
        </RoundedBox>
        {/* Right cushion */}
        <RoundedBox position={[1.65, 0.75, 0.5]} args={[1.7, 0.6, 2.3]} radius={0.2} smoothness={4}>
          <meshStandardMaterial color="#5A7A7A" />
        </RoundedBox>
        {/* Left side pillow */}
        <mesh position={[-2.1, 1.25, 0]} rotation={[Math.PI / 2, 0, 0]}>
          <cylinderGeometry args={[0.2, 0.2, 1.8, 32]} />
          <meshStandardMaterial color="#7F9C9D" />
        </mesh>
        {/* Right side pillow */}
        <mesh position={[1.8, 1.25, 0]} rotation={[Math.PI / 2, 0, Math.PI / 9]}>
          <cylinderGeometry args={[0.2, 0.2, 1.8, 32]} />
          <meshStandardMaterial color="#7F9C9D" />
        </mesh>
        {/* Seat cushions */}
        <RoundedBox position={[0, 0.4, 0.5]} args={[5, 0.6, 2.3]} radius={0.2} smoothness={4}>
          <meshStandardMaterial color="#6B8E8D" />
        </RoundedBox>
        {/* Seat legs */}
        <mesh position={[-2, 0.1, 1.3]}>
          <cylinderGeometry args={[0.2, 0.2, 0.4, 32]} />
          <meshStandardMaterial color="#4A5D5D" />
        </mesh>
        <mesh position={[2, 0.1, 1.3]}>
          <cylinderGeometry args={[0.2, 0.2, 0.4, 32]} />
          <meshStandardMaterial color="#4A5D5D" />
        </mesh>
        <mesh position={[-2, 0.1, -1.3]}>
          <cylinderGeometry args={[0.2, 0.2, 0.4, 32]} />
          <meshStandardMaterial color="#4A5D5D" />
        </mesh>
        <mesh position={[2, 0.1, -1.3]}>
          <cylinderGeometry args={[0.2, 0.2, 0.4, 32]} />
          <meshStandardMaterial color="#4A5D5D" />
        </mesh>
      </group>
    </mesh>
  );
};

export default Sofa;
